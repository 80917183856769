export const peopleVideo = [
    {category: "OPERATIONS", 
        info:[
            {employee: 'Jagjit S', fullName: 'Jagjit Singh', team: 'Loans / Conditions QA', video: 'https://www.youtube.com/watch?v=CJSmtXWBbz0', src: require('../../images/employee/JAGJIT-SINGH.png')},
            {employee: 'Mayank S', fullName: 'Mayank Sharma', team: 'Loans / Conditions QA', video: 'https://www.youtube.com/watch?v=1h9F0XMTlnM', src: require('../../images/employee/MAYANK-SHARMA.png')},
            {employee: 'Rahul B', fullName: 'Rahul Bangabash', team: 'Docs Department', video: 'https://www.youtube.com/watch?v=yhMbb3w-nPc', src: require('../../images/employee/RAHUL-BANGABASH.png')},
            {employee: 'Rohit P', fullName: 'Rohit Pawar', team: 'Loans / Conditions QA', video: 'https://www.youtube.com/watch?v=M_87U-03Z_M', src: require('../../images/employee/ROHIT-PAWAR.png')},
            {employee: 'Sanchit T', fullName: 'Sanchit Todi', team: 'Loans / Conditions QA', video: 'https://www.youtube.com/watch?v=50SEFvAuK6Y', src: require('../../images/employee/SANCHIT-TODI.png')},
            {employee: 'Udayashankar N', fullName: 'Udayashankar Nandi', team: 'Shipping', video: 'https://www.youtube.com/watch?v=TuKXTBLhM88', src: require('../../images/employee/UDAYSHANKAR-NANDI.png')}
        ]
    },
    {category: "IT", 
        info:[
            // {employee: 'Akash S', fullName: 'Akash Sharma', team: 'Software Development', video: 'https://www.youtube.com/watch?v=CZRap2-2rj4&authuser=0', src: require('../../images/employee/AKASH-SHARMA.png')},
            {employee: 'Ankita P', fullName: 'Ankita Porwal', team: 'Software Development', video: 'https://www.youtube.com/watch?v=-4JX9YS08No', src: require('../../images/employee/ANKITA-PORWAL.png')},
            {employee: 'Arshad A', fullName: 'Arshad Ansari', team: 'Software Development', video: 'https://www.youtube.com/watch?v=EejzDN09p8A', src: require('../../images/employee/ARSHAD-ANSARI.png')},
            {employee: 'Mayappa P', fullName: 'Mayappa Patil', team: 'Software Development', video: 'https://www.youtube.com/watch?v=A7IwLcYgyhY&authuser=0', src: require('../../images/employee/MAYAPPA-PATIL.png')}
        ]    
    },
    {category: "SUPPORT",
        info:[
            {employee: 'Meghna G', fullName: 'Meghna Gujarathi', team: 'Career Management', video: 'https://www.youtube.com/watch?v=GKtJBu4cs-E', src: require('../../images/employee/MEGHNA-GUJARATHI.png')},
            {employee: 'Pradyut S', fullName: 'Vithal Sheshayya', team: 'Pradyut Sinha', team: 'Offshore Accounting Audit', video: 'https://www.youtube.com/watch?v=MAlPjcV3IJU', src: require('../../images/employee/PRADYUT-SINHA.png')},
            {employee: 'Sidhanth N', fullName: 'Vithal Sheshayya', team: 'Sidhanth Naidu', team: 'Knowledge Base', video: 'https://www.youtube.com/watch?v=vf0DDE0pUgw&t=2s', src: require('../../images/employee/SIDHANTH-NAIDU.png')},
            {employee: 'Vithal S', fullName: 'Vithal Sheshayya', team: 'Marketing', video: 'https://www.youtube.com/watch?v=ltRXtB7WUZo', src: require('../../images/employee/VITHAL-SHESHAYYA.png')}
        ]
    },
    {category: "SERVICING", 
        info:[
            {employee: 'Rahul P', fullName: 'Rahul Pandita', team: 'Servicing QA', video: 'https://www.youtube.com/watch?v=FUxNMr2mjkQ', src: require('../../images/employee/RAHUL-PANDITA.png')},
            {employee: 'Siddhi K', fullName: 'Siddhi Kulkarni', team: 'Servicing KB', video: 'https://www.youtube.com/watch?v=phkatqKlGVg', src: require('../../images/employee/SIDDHI-KULKARNI.png')}
        ]
    },
    {category: "WOMEN AT XLD", 
        info:[
            {employee: 'Each for Equal', team: '', video: 'https://www.youtube.com/watch?v=6FiQTn5Sylg', src: require('../../images/employee/Each-for-equal.png')},
            {employee: 'Safety & Security', team: '', video: 'https://www.youtube.com/watch?v=MsjKlK_AHZI', src: require('../../images/employee/Safety-and-security.png')},
            {employee: 'XLD for Women', team: '', video: 'https://www.youtube.com/watch?v=6fZN8ENxMoY', src: require('../../images/employee/XLD-for-women.png')},
            {employee: 'Xlerate Your Career', team: '', video: 'https://www.youtube.com/watch?v=L7ss_Q15s1I', src: require('../../images/employee/Exlerete-your-career.png')}
        ]
    },
]