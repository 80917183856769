import React from "react";
import { Helmet } from "react-helmet";
import { Row, Col } from "react-bootstrap";
import { momentImages } from "./Includes/momentImageImport";
import CreateLightbox from "../components/CreateLightbox";

const TITLE = "Mega Recruitment Drive Moments - XL Dynamics India Pvt. Ltd";

function Moments() {
    return (
        <React.Fragment>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Row className="removeMarginLR">
                <Col xs={1}></Col>
                <Col xs={10}>
                    <div className="x-head" style={{height: "50px"}}>Mega Recruitment Drive Moments</div>
                    <p><strong className="themeTextRed">Please note:</strong> Some images have been blurred to protect the identity of the Employee / Candidate.</p>
                    <CreateLightbox photos={momentImages} />
                </Col>
                <Col xs={1}></Col>
            </Row>
        </React.Fragment>
    );
}

export default Moments;