import React from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Image } from 'react-bootstrap';

const TITLE = "Sitemap - XL Dynamics India Pvt. Ltd"

function Sitemap() {
    return(
        <React.Fragment>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Row className="removeMarginLR">
                {/* <Image className="d-block w-100" style={{padding:"10px"}} src={FucntionsBanner} fluid /> */}
                <Col xs={1}></Col>
                <Col xs={5}> 
                    <h1 className="x-head" style={{height: "50px"}}>Site Map</h1>
                    <ul>
                        <li><a href="#">IT solutions</a></li>
                        <li><a href="#">Expert analysis, assessment and audit of complex financial products</a></li>
                        <li><a href="#">Inspection of executed financial trades</a></li>
                        <li><a href="#">Documentation of policies, processes and guidelines</a></li>
                        <li><a href="#">Quality control services, Operations and Servicing quality control</a></li>
                        <li><a href="#">Trading and hedging strategy and support</a></li>
                        <li><a href="#">Regulatory compliance and audit services</a></li>
                        <li><a href="#">Securitization of loan instruments study</a></li>
                    </ul>
                </Col>
                <Col xs={5}>
                    <h1></h1>
                    <ul>
                        <li><a href="#">IT solutions</a></li>
                        <li><a href="#">Expert analysis, assessment and audit of complex financial products</a></li>
                        <li><a href="#">Inspection of executed financial trades</a></li>
                        <li><a href="#">Documentation of policies, processes and guidelines</a></li>
                        <li><a href="#">Quality control services, Operations and Servicing quality control</a></li>
                        <li><a href="#">Trading and hedging strategy and support</a></li>
                        <li><a href="#">Regulatory compliance and audit services</a></li>
                        <li><a href="#">Securitization of loan instruments study</a></li>
                    </ul>
                </Col>
                <Col xs={1}></Col>
            </Row>
        </React.Fragment>
    );
}

export default Sitemap;