import React from 'react'
import { TiStarFullOutline } from 'react-icons/ti'
import { Card } from 'react-bootstrap'

function TestimonialTab(props) {
    const name = props.tData.tmtName;
    const testimonial = props.tData.tmtTestimonial;
    const rating = props.tData.Rating;

    return(
        <Card border="danger">
            <Card.Body>
                <blockquote className="mb-0">
                    <p className="font-italic">{testimonial}</p>
                    <footer className="blockquote-footer text-right">
                        <span className="text-danger font-italic"><strong>{name}</strong> 
                        {Array(rating).fill(<TiStarFullOutline />)}
                        </span>
                    </footer>
                </blockquote>
            </Card.Body>
        </Card>
        // <blockquote className="mb-0 card-body">
        //     <p className="font-italic">{testimonial}</p>
        //     <footer className="blockquote-footer text-right">
        //         <span className="text-danger font-italic"><strong>{name}</strong> {Array(rating).fill(<TiStarFullOutline />)}</span>
        //     </footer>
        //     <hr></hr>
        // </blockquote>
    );
}

export default TestimonialTab;