export const facilityOfficeImages = [
    { src: require('../../images/Facilities/Office Premises/1.jpg'), width: 3, height: 2 },
    { src: require('../../images/Facilities/Office Premises/2.jpg'), width: 4, height: 2 },
    { src: require('../../images/Facilities/Office Premises/3.jpg'), width: 3, height: 2 },
    { src: require('../../images/Facilities/Office Premises/4.jpg'), width: 3, height: 2 },
    { src: require('../../images/Facilities/Office Premises/5.jpg'), width: 3, height: 2 },
    { src: require('../../images/Facilities/Office Premises/6.jpg'), width: 3, height: 2 },
    { src: require('../../images/Facilities/Office Premises/7.jpg'), width: 3, height: 2 },
    { src: require('../../images/Facilities/Office Premises/8.jpg'), width: 3, height: 2 },
    { src: require('../../images/Facilities/Office Premises/9.jpg'), width: 3, height: 2 },
    { src: require('../../images/Facilities/Office Premises/10.jpg'), width: 3, height: 2 }
]