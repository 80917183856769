import React from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Image } from 'react-bootstrap';

const TITLE = "XL Dynamics India Pvt. Ltd"

function CreativeProfile({openingCode}) {
    return(
    openingCode == "Creative-head" ?
        <React.Fragment>
            <ul>
                <li className="hide-list"><span style={{display: "inline"}} className="themeTextRed"><strong>Position:</strong> </span>Creative Head</li>
                <li><span style={{display: "inline"}} className="themeTextRed"><strong>Job Shift:</strong> </span>Night</li>
                <li><span style={{display: "inline"}} className="themeTextRed"><strong>Experience:</strong> </span>10 Years+</li>
                <li><span style={{display: "inline"}} className="themeTextRed"><strong>Education:</strong></span> <span>Any Graduate Degree / Diploma. We hire based on individual talent, skill and work ethic. Formal degrees are not material in our hiring decision.</span></li>
                <li><span style={{display: "inline"}} className="themeTextRed"><strong>Job Location:</strong> </span>You will currently work from home.<br/> If we resume working from the office, you may work from any of these locations: Navi Mumbai, Mumbai, Bengaluru, Kolkata, Pune, Ahmedabad, Jaipur, Indore, Lucknow, Kochi, Guwahati, Delhi NCR (Gurugram).</li>
                <li><span style={{display: "inline"}} className="themeTextRed"><strong>Salary Range:</strong> </span>There is no limit on compensation for the right candidate.</li>
            </ul>
           <div>
           <p style={{display: "inline"}} className="themeTextRed"><strong>KEY RESPONSIBILITIES:</strong></p>
           <ul>
               <li>Work closely with our Client’s Sr. Management in meeting all goals related to Media Campaigns, Brand Building and Management, Social Media Management, ORM, SEO or other Marketing Projects.</li>
               <li>Head and direct the entire Media and Communications Teams viz. Graphics Design, Content Writing, Social Media Marketing and Management, and Lead Marketing and Management; to achieve all Project Goals.</li>
                <li>Visualize and innovate marketing strategies to achieve objectives to improve sales, penetrate new markets, branding, improve recruitment and retention, promote media events, social media marketing and online reputation management.</li>
                <li>Ensure high quality content, graphics, videos, memes, etc. are delivered to the Client to meet objectives.</li>
                <li>Improve the quality of work delivered by Graphic Designers, Content Writers and Social Media managers to improve their capabilities and help them achieve their full potential.</li>
                <li>Evaluate the effectiveness of every campaign, identify areas for improvement and ensure they are not repeated in current and future campaigns.</li>
                <li>Ensure all winning strategies are documented in the Knowledgebase for reference and reuse when necessary.</li>
                <li>Meet daily with the Client&#39;s Sr. Management to provide regular updates on projects and implement their instructions.</li>
                <li>Meet daily with the Teams to monitor progress, address issues and set expectations to ensure all projects are on the right track.</li>
                <li>Communicate with internal staff at XL Dynamics or the Client or vendors or 3rd Parties to execute projects.</li>
                <li>Meet regularly with Sr. Management at XL Dynamics on projects related to XL Dynamics’ Marketing, Recruitment and Social Media goals.</li>
           </ul>
           <p style={{display: "inline"}} className="themeTextRed"><strong>OUR CLIENT:</strong></p>
           <p>Our Client is proud to be a household name within the US (USA) Mortgage industry. An industry
            leader with a focus towards client satisfaction and streamlined technology to deliver low rates,
            cost savings and great service.</p>

            <p>They are one of the largest privately owned mortgage lenders and servicers. If the loan makes
            sense, we close it.</p>

            <p>They are HUD approved mortgagee (FHA &amp; HECM), VA LAPP approved, USDA, Fannie Mae
            and Freddie Mac approved Seller/Servicer and Ginnie Mae Issuer. They are also a leader in
            HMBS securitization and residential and commercial MBS.</p>

            <p>Our Client has created Empathetic AI Technology for the Mortgage Industry and introduced
            Blockchain into Real Estate Transactions to simplify high value financial transactions, like home
            financing, without stress and frustration for all parties involved.</p>
          
            <p style={{display: "inline"}} className="themeTextRed"><strong>OUR DESIRED CREATIVE HEAD MUST:</strong></p>
           <ul>
               <li>Have excellent English Communication (Written and Verbal skills).</li>
               <li>Have a track record of successfully executing Social Media Campaigns, Events and Product Launches, Media Campaigns, Promotions, improving Website Content and Appearance, SEO, ORM, managing YouTube / Instagram / TikTok and other Social Media Channels, maintaining excellent ratings on all platforms.</li>
                <li>Able to quickly align to the vision of the Client and offer innovative solutions and strategies to achieve them.</li>
                <li>Must be very detail oriented, organized and self-disciplined.</li>
                <li>Take ownership of Projects, meticulously plan, regularly monitor them and ensure high quality delivery within timelines.</li>
                <li>Motivate and inspire Team members to work to their full potential.</li>
                <li>Be willing to always go the extra mile along with the Team to achieve goals, when necessary.</li>
                <li>Work on challenging projects, even when out of comfort zones. Always be willing to learn new techniques, technologies, processes, etc. and encourage the team to learn them as well.</li>
           </ul>
           </div>
        </React.Fragment>     
  :
     <React.Fragment>
     <ul>
         <li className="hide-list"><span style={{display: "inline"}} className="themeTextRed"><strong>Position:</strong> </span>Creative Lead</li>
         <li><span style={{display: "inline"}} className="themeTextRed"><strong>Job Shift:</strong> </span>Night</li>
         <li><span style={{display: "inline"}} className="themeTextRed"><strong>Experience:</strong> </span>5 Years+</li>
         <li><span style={{display: "inline"}} className="themeTextRed"><strong>Education:</strong></span> <span>We hire based on individual talent, skill and work ethic. Formal degrees are not material in our hiring decision.</span></li>
         <li><span style={{display: "inline"}} className="themeTextRed"><strong>Job Location:</strong> </span>You will currently work from home.<br/> If we resume working from the office, you may work from any of these locations: Navi Mumbai, Mumbai, Bengaluru, Kolkata, Pune, Ahmedabad, Jaipur, Indore, Lucknow, Kochi, Guwahati, Delhi NCR (Gurugram).</li>
         <li><span style={{display: "inline"}} className="themeTextRed"><strong>Salary Range:</strong> </span>There is no limit on compensation for the right candidate.</li>
     </ul>
    <div>
    <p style={{display: "inline"}} className="themeTextRed"><strong>KEY RESPONSIBILITIES:</strong></p>
    <ul>
        <li>Work closely with our Client’s Sr. Management and Creative Head in understanding Marketing and Social media goals.</li>
        <li>Manage the Graphics Design and Content Writing Teams to achieve all Project Goals.</li>
         <li>Ensure the Team creates high quality content, graphics, videos, memes, etc. in line with the Client and Creative Head’s specifications.</li>
         <li>Improve the quality of work delivered by Graphic Designers and Content Writers through constructive feedback and training.</li>
         <li>Identify areas for improvement from Client and Creative Head feedback and ensure they are corrected immediately not repeated by any Team Member again.</li>
         <li>Document all updates, SOPs, Checklists, Artwork, Designs, videos, etc. in the Knowledgebase for reference and reuse when necessary.</li>
         <li>Meet daily with the Creative Head to provide progress on all Projects and to get instructions on working on current and new projects.</li>
         <li>Meet daily with the Teams to monitor progress, address issues and set expectations to ensure all projects are on the right track.</li>
         <li>Communicate with internal staff at XL Dynamics or the Client or vendors or 3rd Parties to execute projects.</li>
    </ul>
    <p style={{display: "inline"}} className="themeTextRed"><strong>OUR CLIENT:</strong></p>
    <p>Our Client is proud to be a household name within the US (USA) Mortgage industry. An industry
     leader with a focus towards client satisfaction and streamlined technology to deliver low rates,
     cost savings and great service.</p>

     <p>They are one of the largest privately owned mortgage lenders and servicers. If the loan makes
     sense, we close it.</p>

     <p>They are HUD approved mortgagee (FHA & HECM), VA LAPP approved, USDA, Fannie Mae
     and Freddie Mac approved Seller/Servicer and Ginnie Mae Issuer. They are also a leader in
     HMBS securitization and residential and commercial MBS.</p>

     <p>Our Client has created Empathetic AI Technology for the Mortgage Industry and introduced
     Blockchain into Real Estate Transactions to simplify high value financial transactions, like home
     financing, without stress and frustration for all parties involved.</p>
   
     <p style={{display: "inline"}} className="themeTextRed"><strong>OUR DESIRED CREATIVE HEAD MUST:</strong></p>
    <ul>
        <li>Have excellent English Communication (Written and Verbal skills).</li>
        <li>Have a track record of successfully managing Graphics Design and Content Writing Teams.</li>
         <li>Be able to create high quality graphic designs and write excellent content.</li>
         <li>Be an expert in using Adobe Photoshop, Premier Pro and other graphic creation software.</li>
         <li>Must be proficient at creating and posting content to websites.</li>
         <li>Must be very detail oriented, organized and self-disciplined.</li>
         <li>Take ownership of tasks and ensure they are completed within timelines and with the highest quality.</li>
         <li>Motivate and inspire Team members to work to their full potential</li>
         <li>Be willing to always go the extra mile along with the Team to achieve goals, when necessary.</li>
         <li>Work on challenging projects, even when out of comfort zones. Always be willing to learn new techniques, technologies, processes, etc. and encourage the team to learn them as well.</li>
    </ul>
    </div>
 </React.Fragment>
    );
}

export default CreativeProfile;