import React, { useState, useEffect  } from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Jumbotron, Container, Form, Button, Modal, Image, Toast, Spinner, Nav, Card } from 'react-bootstrap';
import axios from 'axios';
import CareerBanner from '../images/banners/CurrentOpenings1-banner.jpg';
import { Link } from 'react-router-dom';
import './CandidateKYC.css';
import { event } from 'react-ga';
// import openingData from './Includes/CurrentOpenings.json';

const TITLE = "Job Application - XL Dynamics India Pvt. Ltd"

function serialize(form) {
    let inputs = form.elements;
    let testArray = {};
    for(let i=0; i < inputs.length; i++) {
        testArray[(inputs[i].id)] = inputs[i].value;
    }
    return (testArray);
}

function CandidateKYC({ match, location}) {
    // console.log(this.props)
    const [getData, setgetData] = useState([]);    
    const [selectedFile, setFileSelection] = useState([]);
    
    const [validated, setValidated] = useState(false);
    const [showMainBlock, setShowMainBlock] = useState(true);
    
    const [showTY, setTYModal] = useState(false)
    const [showFail, setFailModal] = useState(false)

    // const handleTYClose = () => setTYModal(false)
    const handleFailClose = () => setFailModal(false)

    
    const [loading, setLoading] = useState(false);
    
    const [successToast, setSuccessToast] = useState(false);
    const [warningToast, setWarningToast] = useState(false);
    const [toastMsg, setToastMsg] = useState("");
    
    // const handleErrorClose = () => setWarningToast(false)
    const [url, setUrl] = useState('');

    const [employment1, setEmployment1] = useState(false);
    const [employment2, setEmployment2] = useState(false);
    const [postGraduation, setPostGraduation] = useState(false);
    const [Graduation, setGraduation] = useState(false);
    const [email, setEmail] = useState("");
    const [ip, setIP] = useState('');
    const [loadModal, setLoadModal] = useState(true);

    const [salarySlipFirst, setSalarySlipFirst] = useState(false)
    const [salarySlipSecond, setSalarySlipSecond] = useState(false)

    useEffect(() => {
        fetch(process.env.REACT_APP_CURRENT_OPENINGS_CDN_URL)
        .then(response => response.json())
        .then(
            // handle the result
            (result) => {
                setgetData(result)
            }
        )
        getIPData();
        if (localStorage.getItem('successIP') == ip) {
            setShowMainBlock(false)
            setLoadModal(false)
        }
    },[ip]);
    const {
        params: {OpeningName}
    } = match ? match : {params:{OpeningName: null}}
    var selectOptionNew = ""
    const selectedOption = getData.map(oData => {
        let title = oData.openingTitle;
        if(OpeningName === oData.openingCode && oData.visible){
            selectOptionNew = title
            return <option key={oData.openingId} value={title}>{title}</option>;
        } else{ return null;}
    });

    const openingsList = getData.map(oData => {
        let title = oData.openingTitle;
        if(OpeningName !== oData.openingCode && oData.visible){
            return <option key={oData.openingId} value={title}>{title}</option>;
        }
    });


    function refreshPage() {
        window.location.reload(false);
        setTYModal(false)        
        setFailModal(false)
    }

    function handleproceed() {
        setLoadModal(false)
    }

    const getIPData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        console.log(res.data);
        setIP(res.data.IPv4)
    }

    
    const checkMimeType = (event)=>{
        //getting file object
        let files = event.target.files 
        //define message container
        let err = []
        // list allow mime type
        const types = ['image/png', 'image/jpeg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
        
        // loop access array
        for(var x = 0; x<files.length; x++) {
            // compare file type find doesn't matach
            if (types.every(type => files[x].type !== type)) {
                // create error message and assign to container   
                err[x] = 'Uploaded file format is not supported. Please upload png, jpeg, pdf or word file format.';
            }
        };
        for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
            // discard selected file
            setToastMsg(err[z]);
            event.target.value = null;
            setWarningToast(true);
            return false;
        }
        return true;
    }
    
    const maxSelectFile = (event)=>{
        let files = event.target.files
        if (files.length > 1) { 
            setToastMsg('Only 1 file can be uploaded at a time');
            event.target.value = null;
            setWarningToast(true);
            return false;
        }
        return true;
    }

    const checkFileSize = (event)=>{
        let files = event.target.files
        let size = 2000000 
        let err = []; 
        for(var x = 0; x<files.length; x++) {
            if (files[x].size > size) {
                err[x] = files[x].type+' is too large, please select a file less than 2MB';                
            }
        };
        for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
            // discard selected file
            // alert(err[z]);
            setToastMsg(err[z]);
            event.target.value = null;
            setWarningToast(true);
            return false;
        }
        return true;
    }

    const onChangeHandler = event => {
        var files = event.target.files;
        if(maxSelectFile(event) && checkMimeType(event) && checkFileSize(event)) {
            let file_id = event.target.id;
            let file_reader = new FileReader();
            let file = event.target.files[0];

            var fileParts = files[0].name.split('.')
            var fileDate = fileParts[0].replace(/\s/g, '')+Date.now();
            var fileName = fileDate+"."+fileParts[1]

            file_reader.onload = () => {
                const updateArray = {
                    file_id: file_id,
                    file_name: fileName,
                    file_object: file_reader.result
                }
                checkExists(file_id, updateArray);
                console.log('************', selectedFile)
            };
            file_reader.readAsDataURL(file);
        }
        if(files.length > 0){
            setWarningToast(false);
        }
    }
    
    function checkExists(val, updateArray) {        
        var selectedFilesArray = [...selectedFile];
        console.log('old-', selectedFilesArray);
        selectedFilesArray = selectedFilesArray.filter(item => val !== item.file_id);
        console.log('new-', selectedFilesArray);
        setFileSelection([...selectedFilesArray, updateArray]);       
    }
    // useEffect(() => {
    //     setFileSelection(selectedFile);
    // },[selectedFile]);

    function findAwsUrl(apiResponse, id) {
        return apiResponse.filter(item => {
            if(item.id === id) {
                return item.URL;
            }
         });
    }

    function findFileID(id) {
        return selectedFile.filter(item => {
            if(item.file_id === id) {
                return true;
            }
         });
    }
    
    const handleSubmit = event => {    
        event.preventDefault();
        const form = event.currentTarget;
        const config = {
            headers: {
                // "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json'
            },
        }        
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            setLoading(true);
            //upload file to aws 
            if(selectedFile && selectedFile.length > 0){
                axios.post("https://ynyoui2fz5.execute-api.us-east-1.amazonaws.com/prod/python-s3-multiplefile-upload-api", selectedFile)
                    .then(response => {
                        const data = document.querySelector('#candidateData');
                        console.log('API response', response);
                        const apiResponse = response.data.URL;
                        const getData = serialize(data);
                        console.log('form data', getData)                        
                        // getData.jobFile = response.data.URL
                        const today = new Date()
                        let date = parseInt(today.getMonth() + 1) + "/" + today.getDate() + "/" + today.getFullYear();

                        const candidateDetails = {
                            "method": "post",
                            "date": date,
                            "email": getData.personal_email,
                            "aadharCardName": getData.name_aadhar_card,
                            "resumeFile": findFileID('validateResumeFile').length > 0 ? findAwsUrl(apiResponse, 'validateResumeFile')[0].URL : '',
                            "panCardFile": findFileID('validatePanCard').length > 0 ? findAwsUrl(apiResponse, 'validatePanCard')[0].URL : '',
                            "aadharCardFile": findFileID('validatedAadharFile').length > 0 ? findAwsUrl(apiResponse, 'validatedAadharFile')[0].URL : '',
                            "photoFile": findFileID('validateSelfPicCard').length > 0 ? findAwsUrl(apiResponse, 'validateSelfPicCard')[0].URL : '',
                            "passportOrPoliceFile": findFileID('validatePassportFile').length > 0 ? findAwsUrl(apiResponse, 'validatePassportFile')[0].URL : '',
                            "addressProofFile": findFileID('validateAddressFile').length > 0 ? findAwsUrl(apiResponse, 'validateAddressFile')[0].URL : '',
                            "nameChageAffidavitFile": findFileID('validateAffidavitFile').length > 0 ? findAwsUrl(apiResponse, 'validateAffidavitFile')[0].URL : '',
                            "wifiReceiptFile": findFileID('validateWifiReceiptFile').length > 0 ? findAwsUrl(apiResponse, 'validateWifiReceiptFile')[0].URL : '',
                            "postGraduationStatus": getData.postGraduation,
                            "postGratuationFile": findFileID('validatePostGraduationFile').length > 0 ? findAwsUrl(apiResponse, 'validatePostGraduationFile')[0].URL : '',
                            "graduationStatus": getData.Graduation,
                            "graduationFile": findFileID('validateGraduationFile').length > 0 ? findAwsUrl(apiResponse, 'validateGraduationFile')[0].URL : '',
                            "DiplomaHSCFile": findFileID('validateHscDiplomaFile').length > 0 ? findAwsUrl(apiResponse, 'validateHscDiplomaFile')[0].URL : '',
                            "SSCFile": findFileID('validateSSCFile').length > 0 ? findAwsUrl(apiResponse, 'validateSSCFile')[0].URL : '',
                            "experiencedStatus": getData.employmentDetails,
                            "offerorAppoinmentLetterFile1": findFileID('validate1stOrgLetterFile').length > 0 ? findAwsUrl(apiResponse, 'validate1stOrgLetterFile')[0].URL : '',
                            "relievingLetterFile1": findFileID('validate1stOrgRelievingFile').length > 0 ? findAwsUrl(apiResponse, 'validate1stOrgRelievingFile')[0].URL : '',
                            "experienceLetterFile1": findFileID('validate1stOrgExperienceFile').length > 0 ? findAwsUrl(apiResponse, 'validate1stOrgExperienceFile')[0].URL : '',
                            "salaryReceiveBy1": getData.OrgSalary1,
                            "salarySlipFile1": findFileID('validate1stOrgSalarySlip').length > 0 ? findAwsUrl(apiResponse, 'validate1stOrgSalarySlip')[0].URL : '',
                            "EmployeerStatus2": getData.prevEmploymentDetails,
                            "offerorAppoinmentLetterFile2": findFileID('validate2ndOrgLetterFile').length > 0 ? findAwsUrl(apiResponse, 'validate2ndOrgLetterFile')[0].URL : '',
                            "relievingLetterFile2": findFileID('validate2ndOrgRelievingFile').length > 0 ? findAwsUrl(apiResponse, 'validate2ndOrgRelievingFile')[0].URL : '',
                            "experienceLetterFile2": findFileID('validate2ndOrgExperienceFile').length > 0 ? findAwsUrl(apiResponse, 'validate2ndOrgExperienceFile')[0].URL : '',
                            "salaryReceiveBy2": getData.OrgSalary2,
                            "salarySlipFile2": findFileID('validate2ndOrgSalarySlip').length > 0 ? findAwsUrl(apiResponse, 'validate2ndOrgSalarySlip')[0].URL : '',
                        }

                        axios.post('https://v5fpflyj6j.execute-api.us-west-1.amazonaws.com/prod/candidate-kyc-save', candidateDetails)
                            .then(res => {
                                console.log(res)
                                localStorage.setItem('successIP', ip)
                                setTYModal(true)
                            })
                            .catch(function (error) {
                                setFailModal(true);                             
                                console.log("candidate KYC-Data error status " + JSON.stringify(error));
                            })
                    })
                    .catch(error => {
                        setFailModal(true);
                        // alert("ERROR " + JSON.stringify(error));
                        console.log("Put error status " + JSON.stringify(error));
                    })
                
            } else {
                // setToastMsg('Please select the resume file ');
                setWarningToast(true)
            }
        }        
        // setLoading(false);
        setValidated(true);
    };

    const onselectionchange = event => {
        if(event.target.value == 'Experienced') {
            setEmployment1(true)
            // document.querySelector('#candidateData').elements[17].required = true;
            // document.querySelector('#candidateData').elements[18].required = true;
            // document.querySelector('#candidateData').elements[19].required = true;
            // document.querySelector('#candidateData').elements[20].required = true;
            // document.querySelector('#candidateData').elements[21].required = true;
        } else {
            setEmployment1(false)
            // document.querySelector('#candidateData').elements[17].required = false;
            // document.querySelector('#candidateData').elements[18].required = false;
            // document.querySelector('#candidateData').elements[19].required = false;
            // document.querySelector('#candidateData').elements[20].required = false;
            // document.querySelector('#candidateData').elements[21].required = false;
        }        
    };

    const onEmpSelectChange = event => {
       if(event.target.value == 'PrevYes') {
            setEmployment2(true)
            // document.querySelector('#candidateData').elements[23].required = true;
            // document.querySelector('#candidateData').elements[24].required = true;
            // // document.querySelector('#candidateData').elements[25].required = true;
            // document.querySelector('#candidateData').elements[26].required = true;
            // // document.querySelector('#candidateData').elements[27].required = true;
       } else{
           setEmployment2(false)
        //    document.querySelector('#candidateData').elements[23].required = false;
        //    document.querySelector('#candidateData').elements[24].required = false;
        // //    document.querySelector('#candidateData').elements[25].required = false;
        //    document.querySelector('#candidateData').elements[26].required = false;
        // //    document.querySelector('#candidateData').elements[27].required = false;
       }
    }

    const onSelectPostGraduation = event => {        
        if(event.target.value == 'Yes' || event.target.value == 'Pursuing' ) {
            setPostGraduation(true) 
            document.querySelector('#candidateData').elements[11].required = true;
        } else {
            setPostGraduation(false);
            document.querySelector('#candidateData').elements[11].required = false;
        }
    }

    const onSelectGraduation = event => {

        if(event.target.value == 'YesG' || event.target.value == 'PursuingG') {
            setGraduation(true)
            document.querySelector('#candidateData').elements[13].required = true;
        } else {
            setGraduation(false)
            document.querySelector('#candidateData').elements[13].required = false;
        }
    }

    const onSalarychange = event => {
        if(event.target.value == 'NetBanking') {
            setSalarySlipFirst(true);
            // document.querySelector('#candidateData').elements[21].required = true;
        } else {
            setSalarySlipFirst(false);
            // document.querySelector('#candidateData').elements[21].required = false;
        }
    }

    const onSalarychangeSecond = event => {
        if(event.target.value == 'NetBankingSecond') {
            setSalarySlipSecond(true);
            // document.querySelector('#candidateData').elements[27].required = true;
        } else {
            setSalarySlipSecond(false);
            // document.querySelector('#candidateData').elements[27].required = false;
        }
    }

    const handleErrorClose = () => {
        setWarningToast(false);
    }

    const handleTYClose = () => {
        setTYModal(false);
        setShowMainBlock(false)
    }
    return(        
        <React.Fragment>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Row className="removeMarginLR">
                <Col xs={1}></Col>
                <Col xs={10} className="small-screen">
                    <h1 className={"x-head "+(location.search.length > 0 || location.pathname == "/dolphins" ? ' d-none' : ' d-block')}  style={{height: "50px"}}>Documents Upload Form V1.1</h1>
                    <span>You are required to fill this form and upload the documents as requested, without documents verification, your joining confirmation would not be processed.</span>
                    <Jumbotron style={{backgroundColor: 'white'}} fluid >
                        <Container fluid className={`${showMainBlock && !loadModal ? 'emp-1-block' : 'display-none'}`}>                      
                            <Form id="candidateData" noValidate validated={validated} encType="multipart/form-data" onSubmit={handleSubmit} method="POST" autoComplete="off">
                                <div className="block-panel">
                                    <h4 className="themeTextRed d-block" >Personal Details</h4>                                  
                                    <Form.Row>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="personal_email">
                                            <strong><Form.Label>* Personal Email ID: </Form.Label></strong>
                                            <Form.Control type="email" data-error="Please fill out this field correctly." placeholder="Enter Personal Email ID" required />
                                            <Form.Control.Feedback type="invalid">Please enter Personal Email ID</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="name_aadhar_card">
                                            <strong><Form.Label>* Name as per Aadhaar Card: </Form.Label></strong>
                                            <Form.Control type="text" placeholder="Enter Name as per Aadhaar Card" data-error="Please fill out this field correctly." required />
                                            <Form.Control.Feedback type="invalid">Please enter Name as per Aadhaar Card</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                  
                                    <Form.Row>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="jobFile" >
                                            <strong><Form.Label>* Resume <span className="sub-header">(Upload the copy of your Updated Resume)</span>:</Form.Label></strong>
                                            <div className="custom-file">
                                                <Form.Control type="file" className="custom-file-input" onChange={onChangeHandler} id="validateResumeFile" required  />
                                                <Form.Label className="custom-file-label" value="selected" for="validateResumeFile">                                                    
                                                    {selectedFile && selectedFile.length > 0 &&
                                                        selectedFile.map((item) => 
                                                        item.file_id === "validateResumeFile" &&
                                                        <span className="filename-trim">{item.file_name}</span>                                                                
                                                        )
                                                    }
                                                    <span>Choose your Resume</span>
                                                    </Form.Label>
                                                <Form.Control.Feedback type="invalid">Please select a file.</Form.Control.Feedback>                                                
                                            </div>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="panCardFile" >
                                            <strong><Form.Label>* PAN Card <span className="sub-header"> (Upload the copy of your PAN Card)</span>:</Form.Label></strong>
                                            <div className="custom-file">
                                                <Form.Control type="file" className="custom-file-input" onChange={onChangeHandler} id="validatePanCard" required />
                                                <Form.Label className="custom-file-label" value="selected" for="validatePanCard">
                                                    {selectedFile && selectedFile.length > 0 &&
                                                        selectedFile.map((item) => 
                                                        item.file_id === "validatePanCard" &&
                                                        <span className="filename-trim">{item.file_name}</span>                                                                
                                                        )
                                                    }
                                                    <span>Choose your Pan Card</span>
                                                </Form.Label>
                                                <Form.Control.Feedback type="invalid">Please select a file.</Form.Control.Feedback>                                               
                                            </div>
                                        </Form.Group>                                                                              
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="aadharFile" >
                                            <strong><Form.Label>* Aadhaar Card <span className="sub-header">(Upload both Front and Back Side in single file)</span>:</Form.Label></strong>
                                            <div className="custom-file">
                                                <Form.Control type="file" className="custom-file-input" onChange={onChangeHandler} id="validatedAadharFile" required />
                                                <Form.Label className="custom-file-label" value="selected" for="validatedAadharFile">
                                                    {selectedFile && selectedFile.length > 0 &&
                                                        selectedFile.map((item) => 
                                                        item.file_id === "validatedAadharFile" &&
                                                        <span className="filename-trim">{item.file_name}</span>                                                                
                                                        )
                                                    }
                                                    <span>Choose your Aadhaar Card</span>
                                                    </Form.Label>
                                                <Form.Control.Feedback type="invalid">Please select a file.</Form.Control.Feedback>
                                            </div>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="selfPicFile" >
                                            <strong><Form.Label>* Photograph <span  className="sub-header">(Upload Passport size Photograph) </span>:</Form.Label></strong>
                                            <div className="custom-file">
                                                <Form.Control type="file" className="custom-file-input" onChange={onChangeHandler} id="validateSelfPicCard" required />
                                                <Form.Label className="custom-file-label" value="selected" for="validateSelfPicCard">                                                   
                                                     {selectedFile && selectedFile.length > 0 &&
                                                        selectedFile.map((item) => 
                                                        item.file_id === "validateSelfPicCard" &&
                                                        <span className="filename-trim">{item.file_name}</span>                                                                
                                                        )
                                                    }
                                                    <span>Choose your Photograph</span>
                                                </Form.Label>
                                                <Form.Control.Feedback type="invalid">Please select a file.</Form.Control.Feedback>                                               
                                            </div>
                                        </Form.Group>                                                                              
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="passportFile" >
                                            <strong><Form.Label>Passport or Police Verification <span className="sub-header"> (Upload Profile and Address Page if it's Passport)</span>:</Form.Label></strong>
                                            <div className="custom-file">
                                                <Form.Control type="file" className="custom-file-input" onChange={onChangeHandler} id="validatePassportFile" />
                                                <Form.Label className="custom-file-label" value="selected" for="validatePassportFile">
                                                    {selectedFile && selectedFile.length > 0 &&
                                                        selectedFile.map((item) => 
                                                        item.file_id === "validatePassportFile" &&
                                                        <span className="filename-trim">{item.file_name}</span>
                                                        )
                                                    }
                                                    <span>Choose your Passport or Police Verification </span>
                                                    </Form.Label>
                                                <Form.Control.Feedback type="invalid">Please select a file.</Form.Control.Feedback>
                                            </div>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="addressFile" >
                                            <strong><Form.Label>Current Address Proof <span className="sub-header"> (Upload the copy of your Current Address Proof)</span>:</Form.Label></strong>
                                            <div className="custom-file">
                                                <Form.Control type="file" className="custom-file-input" onChange={onChangeHandler} id="validateAddressFile" />
                                                <Form.Label className="custom-file-label" value="selected" for="validateAddressFile">
                                                    {selectedFile && selectedFile.length > 0 &&
                                                        selectedFile.map((item) => 
                                                        item.file_id === "validateAddressFile" &&
                                                        <span className="filename-trim">{item.file_name}</span>
                                                        )
                                                    }
                                                    <span>Choose your Address Proof</span>
                                                </Form.Label>
                                                <Form.Control.Feedback type="invalid">Please select a file.</Form.Control.Feedback>
                                            </div>
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="affidavitFile" >
                                            <strong><Form.Label>Affidavit for Name Change <span className="sub-header"> ( In case applicable )</span>:</Form.Label></strong>
                                            <div className="custom-file">
                                                <Form.Control type="file" className="custom-file-input" onChange={onChangeHandler} id="validateAffidavitFile" />
                                                <Form.Label className="custom-file-label" value="selected" for="validateAffidavitFile">
                                                    {selectedFile && selectedFile.length > 0 &&
                                                        selectedFile.map((item) => 
                                                        item.file_id === "validateAffidavitFile" &&
                                                        <span className="filename-trim">{item.file_name}</span>                                                                
                                                        )
                                                    }
                                                    <span>Choose your Affidavit for Name Change</span>
                                                    </Form.Label>
                                                <Form.Control.Feedback type="invalid">Please select a file.</Form.Control.Feedback>
                                            </div>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="wifiFile" >
                                            <strong><Form.Label>100 MBPS Wifi Receipt <span className="sub-header">(Upload your 100 MBPS Wifi Receipt)</span>:</Form.Label></strong>
                                            <div className="custom-file">
                                                <Form.Control type="file" className="custom-file-input" onChange={onChangeHandler} id="validateWifiReceiptFile" />
                                                <Form.Label className="custom-file-label" value="selected" for="validateWifiReceiptFile">                                                   
                                                    {selectedFile && selectedFile.length > 0 &&
                                                        selectedFile.map((item) => 
                                                        item.file_id === "validateWifiReceiptFile" &&
                                                        <span className="filename-trim">{item.file_name}</span>                                                                
                                                        )
                                                    }
                                                    <span>Choose your Wifi Receipt</span>
                                                </Form.Label>
                                                <Form.Control.Feedback type="invalid">Please select a file.</Form.Control.Feedback>                                               
                                            </div>
                                        </Form.Group>                                                                              
                                    </Form.Row>
                                </div>                               
                                <div className="block-panel">
                                    <h4 className="themeTextRed d-block" >Educational Details</h4>
                                    <Form.Row>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="postGraduation" >
                                            <strong><Form.Label>* Have you done Post Graduation:</Form.Label></strong>
                                            <Form.Control as="select" placeholder="Select below" onChange={onSelectPostGraduation} required >
                                                <option> </option>
                                                <option value="Yes">Yes</option>
                                                <option value="Pursuing">Pursuing</option>
                                                <option value="No">No</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">Please select an option.</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className={`minimum-screen ${postGraduation ? 'emp-1-block' : 'display-none'}`} controlId="postGraduationFile" >
                                            <strong><Form.Label>* Upload the copy of Last Year's Post Graduation Marksheet. If Pursuing, Please upload the copy of latest Marksheet.<span className="sub-header"> (Don't Upload the Provisional Certificate)</span>:</Form.Label></strong>
                                            <div className="custom-file">
                                                <Form.Control type="file" className="custom-file-input" onChange={onChangeHandler} id="validatePostGraduationFile" />
                                                <Form.Label className="custom-file-label" value="selected" for="validatePostGraduationFile">                                                   
                                                    {selectedFile && selectedFile.length > 0 &&
                                                        selectedFile.map((item) => 
                                                            item.file_id === "validatePostGraduationFile" &&
                                                            <span className="filename-trim">{item.file_name}</span>                                                                
                                                        )
                                                    }
                                                    <span>Choose your Post Graduation Marksheet</span>
                                                </Form.Label>
                                                <Form.Control.Feedback type="invalid">Please select a file.</Form.Control.Feedback>                                               
                                            </div>
                                        </Form.Group> 
                                    </Form.Row>
                                    
                                    <Form.Row>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="Graduation" >
                                            <strong><Form.Label>* Have you done Graduation:</Form.Label></strong>
                                            <Form.Control as="select" placeholder="Select below" onChange={onSelectGraduation} required>
                                                <option></option>
                                                <option value="YesG">Yes</option>
                                                <option value="PursuingG">Pursuing</option>
                                                <option value="NoG">No</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">Please select an option.</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className={`minimum-screen ${Graduation ? 'emp-1-block' : 'display-none'}`}  controlId="GraduationFile" >
                                            <strong><Form.Label>* Upload the copy of Last Year's Graduation Marksheet. If Pursuing, Please upload the copy of latest Marksheet.<span className="sub-header"> (Don't Upload the Provisional Certificate)</span>:</Form.Label></strong>
                                            <div className="custom-file">
                                                <Form.Control type="file" className="custom-file-input" onChange={onChangeHandler} id="validateGraduationFile" />
                                                <Form.Label className="custom-file-label" value="selected" for="validateGraduationFile">                                                   
                                                    {selectedFile && selectedFile.length > 0 &&
                                                        selectedFile.map((item) => 
                                                            item.file_id === "validateGraduationFile" &&
                                                            <span className="filename-trim">{item.file_name}</span>                                                                
                                                        )
                                                    }
                                                    <span>Choose your Graduation Marksheet</span>
                                                </Form.Label>
                                                <Form.Control.Feedback type="invalid">Please select a file.</Form.Control.Feedback>                                               
                                            </div>
                                        </Form.Group> 
                                    </Form.Row> 

                                    <Form.Row>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="hscDiplomaFile" >
                                            <strong><Form.Label>* Upload the copy of your HSC / Diploma Marksheet <span className="sub-header"> (Upload Both if done)</span>:</Form.Label></strong>
                                            <div className="custom-file">
                                                <Form.Control type="file" className="custom-file-input" onChange={onChangeHandler} id="validateHscDiplomaFile" required />
                                                <Form.Label className="custom-file-label" value="selected" for="validateHscDiplomaFile">
                                                    {selectedFile && selectedFile.length > 0 &&
                                                        selectedFile.map((item) => 
                                                        item.file_id === "validateHscDiplomaFile" &&
                                                        <span className="filename-trim">{item.file_name}</span>                                                                
                                                        )
                                                    }
                                                    <span>Choose your HSC / Diploma Marksheet </span>
                                                    </Form.Label>
                                                <Form.Control.Feedback type="invalid">Please select a file.</Form.Control.Feedback>
                                            </div>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="SSCFile" >
                                            <strong><Form.Label>* Upload the copy of your SSC Marksheet:</Form.Label></strong>
                                            <div className="custom-file">
                                                <Form.Control type="file" className="custom-file-input" onChange={onChangeHandler} id="validateSSCFile" required />
                                                <Form.Label className="custom-file-label" value="selected" for="validateSSCFile">                                                   
                                                    {selectedFile && selectedFile.length > 0 &&
                                                        selectedFile.map((item) => 
                                                        item.file_id === "validateSSCFile" &&
                                                        <span className="filename-trim">{item.file_name}</span>                                                                
                                                        )
                                                    }
                                                    <span>Choose your SSC Marksheet</span>
                                                </Form.Label>
                                                <Form.Control.Feedback type="invalid">Please select a file.</Form.Control.Feedback>                                               
                                            </div>
                                        </Form.Group>                                                                              
                                    </Form.Row>
                                </div>
                                <div className="block-panel">
                                    <h4 className="themeTextRed d-block">Previous Employment Details</h4>
                                    <Form.Row>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={10} className="minimum-screen" controlId="employmentDetails" >
                                            <strong><Form.Label>* Are you Experienced or a Fresher? <span className="sub-header">(If you have worked in any previous Organization for 6 or more than 6 months then consider yourself as experienced, else fresher)</span>:</Form.Label></strong>
                                            <Form.Control as="select" placeholder="Select below" onChange={onselectionchange} required >
                                                <option></option>
                                                <option value="Experienced">Experienced</option>
                                                <option value="Fresher">Fresher</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">Please select an option.</Form.Control.Feedback>
                                        </Form.Group>                                        
                                    </Form.Row>
                                    <div className={`${employment1 ? 'emp-1-block' : 'display-none'}`}>
                                        <h5 className="themeTextRed d-block">Previous Employment - 1</h5>
                                        <Form.Row>
                                            <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                            <Form.Group as={Col} sm={5} className="minimum-screen" controlId="OrgLetterFile1" >
                                                <strong><Form.Label>Offer / Appointment Letter of the last Organization<span className="sub-header"> (Please upload the copy of the said letter)</span>:</Form.Label></strong>
                                                <div className="custom-file">
                                                    <Form.Control type="file" className="custom-file-input" onChange={onChangeHandler} id="validate1stOrgLetterFile" />
                                                    <Form.Label className="custom-file-label" value="selected" for="validate1stOrgLetterFile">
                                                        {selectedFile && selectedFile.length > 0 &&
                                                            selectedFile.map((item) => 
                                                            item.file_id === "validate1stOrgLetterFile" &&
                                                            <span className="filename-trim">{item.file_name}</span>                                                                
                                                            )
                                                        }
                                                        <span>Choose your Offer / Appointment Letter</span>
                                                        </Form.Label>
                                                    <Form.Control.Feedback type="invalid">Please select a file.</Form.Control.Feedback>
                                                </div>
                                            </Form.Group>
                                            <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                            <Form.Group as={Col} sm={5} className="minimum-screen" controlId="OrgRelievingFile1" >
                                                <strong><Form.Label>Relieving Letter / Resignation Acceptance copy of email of the last Organization <span className="sub-header">(Please upload a copy of the said document)</span> :</Form.Label></strong>
                                                <div className="custom-file">
                                                    <Form.Control type="file" className="custom-file-input" onChange={onChangeHandler} id="validate1stOrgRelievingFile" />
                                                    <Form.Label className="custom-file-label" value="selected" for="validate1stOrgRelievingFile">                                                   
                                                        {selectedFile && selectedFile.length > 0 &&
                                                            selectedFile.map((item) => 
                                                            item.file_id === "validate1stOrgRelievingFile" &&
                                                            <span className="filename-trim">{item.file_name}</span>                                                                
                                                            )
                                                        }
                                                        <span>Choose your Relieving / Resignation Letter</span>
                                                    </Form.Label>
                                                    <Form.Control.Feedback type="invalid">Please select a file.</Form.Control.Feedback>                                               
                                                </div>
                                            </Form.Group>                                                                              
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                            <Form.Group as={Col} sm={5} className="minimum-screen" controlId="OrgExperienceFile1" >
                                                <strong><Form.Label>Experience Letter of the last Organization<span className="sub-header"> (Please upload a copy of the said document.)</span>:</Form.Label></strong>
                                                <div className="custom-file">
                                                    <Form.Control type="file" className="custom-file-input" onChange={onChangeHandler} id="validate1stOrgExperienceFile" />
                                                    <Form.Label className="custom-file-label" value="selected" for="validate1stOrgExperienceFile">
                                                        {selectedFile && selectedFile.length > 0 &&
                                                            selectedFile.map((item) => 
                                                            item.file_id === "validate1stOrgExperienceFile" &&
                                                            <span className="filename-trim">{item.file_name}</span>                                                                
                                                            )
                                                        }
                                                        <span>Choose your Experience Letter</span>
                                                        </Form.Label>
                                                    <Form.Control.Feedback type="invalid">Please select a file.</Form.Control.Feedback>
                                                </div>
                                            </Form.Group>                                                                                                                   
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                            <Form.Group as={Col} sm={5} className="minimum-screen" controlId="OrgSalary1" >
                                                <strong><Form.Label>You used to get your salary in the last Organization through which channel?:</Form.Label></strong>
                                                <Form.Control as="select" onChange={onSalarychange} placeholder="Select below">
                                                    <option></option>
                                                    <option value="NetBanking">Net Banking / In Account</option>
                                                    <option value="CashInHand">Cash In hand / Cheque</option>
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">Please select an option.</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                            <Form.Group as={Col} sm={5} className={`minimum-screen ${salarySlipFirst ? 'emp-1-block' : 'display-none'}`}  controlId="OrgSalarySlip1" >
                                                <strong><Form.Label>3 Months Salary Slip of the last Organization <span className="sub-header"> (Please upload all 3 months Salary Slip in a single file)</span>:</Form.Label></strong>
                                                <div className="custom-file">
                                                    <Form.Control type="file" className="custom-file-input" onChange={onChangeHandler} id="validate1stOrgSalarySlip"  />
                                                    <Form.Label className="custom-file-label" value="selected" for="validate1stOrgSalarySlip">                                                   
                                                        {selectedFile && selectedFile.length > 0 &&
                                                            selectedFile.map((item) => 
                                                                item.file_id === "validate1stOrgSalarySlip" &&
                                                                <span className="filename-trim">{item.file_name}</span>                                                                
                                                            )
                                                        }
                                                        <span>Choose your Salary Slip </span>
                                                    </Form.Label>
                                                    <Form.Control.Feedback type="invalid">Please select a file.</Form.Control.Feedback>                                               
                                                </div>
                                            </Form.Group> 
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                            <Form.Group as={Col} sm={10} className="minimum-screen" controlId="prevEmploymentDetails" >
                                                <strong><Form.Label>Have you worked with any other Organization prior to Previous Employment 1:</Form.Label></strong>
                                                <Form.Control as="select" onChange={onEmpSelectChange} placeholder="Select below">
                                                    <option > </option>
                                                    <option value="PrevYes">Yes</option>
                                                    <option value="PrevNo">No</option>
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">Please select an option.</Form.Control.Feedback>
                                            </Form.Group>                                        
                                        </Form.Row>
                                        <div className={`${employment2 ? 'emp-1-block' : 'display-none'}`}>
                                            <h5 className="themeTextRed d-block">Previous Employment - 2</h5>
                                            <Form.Row>
                                                <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                                <Form.Group as={Col} sm={5} className="minimum-screen" controlId="OrgLetterFile2" >
                                                    <strong><Form.Label>Offer / Appointment Letter of the second last Organization<span className="sub-header"> (Please upload the copy of the said letter)</span>:</Form.Label></strong>
                                                    <div className="custom-file">
                                                        <Form.Control type="file" className="custom-file-input" onChange={onChangeHandler} id="validate2ndOrgLetterFile" />
                                                        <Form.Label className="custom-file-label" value="selected" for="validate2ndOrgLetterFile">
                                                            {selectedFile && selectedFile.length > 0 &&
                                                                selectedFile.map((item) => 
                                                                item.file_id === "validate2ndOrgLetterFile" &&
                                                                <span className="filename-trim">{item.file_name}</span>                                                                
                                                                )
                                                            }
                                                            <span>Choose your Offer / Appointment Letter </span>
                                                            </Form.Label>
                                                        <Form.Control.Feedback type="invalid">Please select a file.</Form.Control.Feedback>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                                <Form.Group as={Col} sm={5} className="minimum-screen" controlId="OrgRelievingFile2" >
                                                    <strong><Form.Label>Relieving Letter / Resignation Acceptance copy of email of the second last Organization <span className="sub-header">(Please upload a copy of the said document)</span> :</Form.Label></strong>
                                                    <div className="custom-file">
                                                        <Form.Control type="file" className="custom-file-input" onChange={onChangeHandler} id="validate2ndOrgRelievingFile" />
                                                        <Form.Label className="custom-file-label" value="selected" for="validate2ndOrgRelievingFile">                                                   
                                                            {selectedFile && selectedFile.length > 0 &&
                                                                selectedFile.map((item) => 
                                                                item.file_id === "validate2ndOrgRelievingFile" &&
                                                                <span className="filename-trim">{item.file_name}</span>                                                                
                                                                )
                                                            }
                                                            <span>Choose your Relieving / Resignation Letter </span>
                                                        </Form.Label>
                                                        <Form.Control.Feedback type="invalid">Please select a file.</Form.Control.Feedback>                                               
                                                    </div>
                                                </Form.Group>                                                                              
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                                <Form.Group as={Col} sm={5} className="minimum-screen" controlId="OrgExperienceFile2" >
                                                    <strong><Form.Label>Experience Letter of the second last Organization<span className="sub-header"> (Please upload a copy of the said document.)</span>:</Form.Label></strong>
                                                    <div className="custom-file">
                                                        <Form.Control type="file" className="custom-file-input" onChange={onChangeHandler} id="validate2ndOrgExperienceFile" />
                                                        <Form.Label className="custom-file-label" value="selected" for="validate2ndOrgExperienceFile">
                                                            {selectedFile && selectedFile.length > 0 &&
                                                                selectedFile.map((item) => 
                                                                item.file_id === "validate2ndOrgExperienceFile" &&
                                                                <span className="filename-trim">{item.file_name}</span>                                                                
                                                                )
                                                            }
                                                            <span>Choose your Experience Letter </span>
                                                            </Form.Label>
                                                        <Form.Control.Feedback type="invalid">Please select a file.</Form.Control.Feedback>
                                                    </div>
                                                </Form.Group>                                                                                                                   
                                            </Form.Row>
                                            <Form.Row>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="OrgSalary2" >
                                            <strong><Form.Label>You used to get your salary in the second last Organization through which channel?:</Form.Label></strong>
                                            <Form.Control as="select" onChange={onSalarychangeSecond} placeholder="Select below" >
                                                <option> </option>
                                                <option value="NetBankingSecond">Net Banking / In Account</option>
                                                <option value="CashInHandSecond">Cash In hand / Cheque</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">Please select an option.</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className={`minimum-screen ${salarySlipSecond ? 'emp-1-block' : 'display-none'}`}  controlId="OrgSalarySlip2" >
                                            <strong><Form.Label>3 Months Salary Slip of the second last Organization <span className="sub-header"> (Please upload all 3 months Salary Slip in a single file)</span>:</Form.Label></strong>
                                            <div className="custom-file">
                                                <Form.Control type="file" className="custom-file-input" onChange={onChangeHandler} id="validate2ndOrgSalarySlip" />
                                                <Form.Label className="custom-file-label" value="selected" for="validate2ndOrgSalarySlip">
                                                    {selectedFile && selectedFile.length > 0 &&
                                                        selectedFile.map((item) =>
                                                            item.file_id === "validate2ndOrgSalarySlip" &&
                                                            <span className="filename-trim">{item.file_name}</span>
                                                        )
                                                    }
                                                    <span>Choose your Salary Slip </span>
                                                </Form.Label>
                                                <Form.Control.Feedback type="invalid">Please select a file.</Form.Control.Feedback>
                                            </div>
                                        </Form.Group>
                                    </Form.Row>
                                        </div>
                                    </div>
                                </div>
                                <Form.Row className="text-center apply-button">
                                    <Form.Group as={Col}>
                                        <Button variant="danger" type="btn btn-lg submit" >
                                            {loading?
                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />:
                                            "Submit"}
                                            {loading?" Please wait...":""}
                                        </Button>
                                    </Form.Group>
                                    {/* <Form.Group as={Col} sm={1}>&nbsp;</Form.Group> */}
                                </Form.Row>
                            </Form>
                        </Container>
                        <Container fluid className={`${!showMainBlock ? 'emp-1-block block-panel' : 'display-none'}`}>
                            <div>Thanks for uploading your KYC documents. For further assistance Please contact you HR.</div>             
                        </Container>
                    </Jumbotron>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Modal size='md' centered show={showTY} backdrop="static">
                <Modal.Body>Thank you for submitting your request to the XLDynamics Team.</Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleTYClose}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal size='lg' centered show={showFail} onHide={handleFailClose} backdrop="static">
                <Modal.Body>Due to technical difficulties your request could not be sent. Please check your internet connection and try again or contact <a href="mailto:resume@xldynamics.com"> resume@xldynamics.com</a></Modal.Body>
                <Modal.Footer>
                    <Button onClick={refreshPage}>Close</Button>
                </Modal.Footer>
            </Modal>

            <Modal size='md' centered show={warningToast} backdrop="static">
                <Modal.Body> {toastMsg} </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleErrorClose}>Close</Button>
                </Modal.Footer>
            </Modal>

            <Modal size='md'centered show={loadModal} backdrop="static">
                <Modal.Body> Start filling the form once you have all the related documents soft copy with you mentioned in email sent to you by resume@xldynamics.com </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleproceed}>Start</Button>
                    <Nav.Link as={Link} to="/" className="btn btn-danger btn-sm">Later</Nav.Link>
                </Modal.Footer>
            </Modal>
            
        </React.Fragment>
    );
}

export default CandidateKYC;