import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Container, CardColumns, Image } from 'react-bootstrap';
import TestimonialTab from './Includes/TestimonialTab';
import TestimonialsBanner from '../images/banners/Testimonials-banner.jpg';

const TITLE = "Testimonials - XL Dynamics India Pvt. Ltd"

class Testimonials extends Component {
    constructor(props){
        super(props);
        this.state = {
            error : null,
            isLoaded : false,
            TmtData : []
        };
    }
    
    componentDidMount() {
        fetch("https://xld-production.s3.amazonaws.com/testimonials/Testimonials.json")
        .then( response => response.json())
        .then(
            // handle the result
            (result) => {
                this.setState({
                    isLoaded : true,
                    TmtData : result
                });
            }
        )
    }

    render() {
        const {TmtData} = this.state;
        const testimonialComps = TmtData.map((tData) => {
            if(tData.Status === "active" && tData.tmtDeleted === 0){
                return <TestimonialTab key={tData.tmtId} tData={tData} />;
            } else {
                return null;
            }
        });

        return(
            <div style={{padding:"10px"}}>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
                <Image className="d-block w-100" src={TestimonialsBanner} fluid />
                <h1 className="x-head" style={{height: "50px"}}>Testimonials</h1>
                <Container fluid>
                    <CardColumns>{testimonialComps}</CardColumns>
                </Container>
            </div>
        );
    }
}

export default Testimonials;