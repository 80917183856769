import React from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Image } from 'react-bootstrap';

const TITLE = "XL Dynamics India Pvt. Ltd"

function CorporateTrainer() {
    return(
        <React.Fragment>
           <div>
           <p style={{display: "inline"}} className="themeTextRed"><strong>Job Description</strong></p>
           <ul>
               <li>Do you have experience in teaching English?</li>
               <li>Are you a curious lifelong learner who thrives on imparting knowledge and skills through interaction?</li>
           </ul>
           <p>Then here’s an amazing chance for you to grow personally and professionally.</p>
           <p style={{display: "inline"}} className="themeTextRed"><strong>
           We are looking for a highly qualified and experienced corporate trainer to:</strong>
           </p>
           <ul>
               <li>Design and lead scalable training and assessment programs that meets business needs.</li>
               <li>Identify skills gaps and learning goals.</li>
               <li>Evaluate and provide actionable feedback to employees. </li>
               <li>Report on employee progress and training impact.</li>
               <li>Help employees communicate with confidence in professional situations.</li>
            </ul>
           <p style={{display: "inline"}} className="themeTextRed"><strong>This role offers you a chance to:</strong></p>
           <ul>
               <li>Immerse yourself in an environment with a sharp learning curve and development opportunities.</li>
               <li>Collaborate with the smartest and most creative leadership teams in the business.</li>
               
           </ul>
           <p><span style={{display: "inline"}} className="themeTextRed"><strong>Salary Range:</strong> </span>Up to Rs.10,00,000 p.a. Salary is not a constraint for the right candidate.</p>
           <p>If you meet the above brief, we want to hear from you.</p>
           <p><span style={{display: "inline"}} className="themeTextRed"><strong>Shift:</strong> </span>Rotational</p>
           <p><span style={{display: "inline"}} className="themeTextRed"><strong>Job Location:</strong> </span>Navi Mumbai, Mumbai, Bengaluru, Kolkata, Pune, Ahmedabad, Jaipur, Indore,
Lucknow, Kochi, Guwahati, Delhi NCR (Gurugram).</p>
           </div>
        </React.Fragment>
    );
}

export default CorporateTrainer;