import React from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Button, Nav, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TnDBanner from '../images/banners/TrainingAndDevelopment-banner.jpg';

const TITLE = "Training and Development - XL Dynamics India Pvt. Ltd"

function TandD() {
    return(
        <React.Fragment>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Row className="removeMarginLR">
                <Image className="d-block w-100" style={{padding:"10px"}} src={TnDBanner} fluid />
                <Col xs={1}></Col>
                <Col>
                    <h1 className="x-head" style={{height: "50px"}}>Training and Development Process</h1>
                    
                    <h6 className="themeTextRed">The Common Induction Program:</h6>
                    All the employees that join the company are put through a Common Induction Program which lasts for 3-5 days. The new employee is introduced to the company, the core members of the organization, the Organization’s work culture and ethics, the Corporate Mission, its achievements and future goals. The employees also go through an intensive training process on the US Mortgage Industry to learn concepts that they would use on a daily basis. The employees also acquire a hands-on experience of working on the ERP systems of the organization and the performance management systems that would track their achievements through their tenure of employment.
                    <p style={{clear:"both"}}></p>

                    <h6 className="themeTextRed">Process Training:</h6>
                    Once the employees complete their Common Induction, they are then moved into specialized responsibilities in the organization and they begin their domain specific training. The training continues for 3 – 4 weeks that involves:
                    <ol>
                        <li>Understanding of the process goals and its importance in the Business Cycle</li>
                        <li>Familiarization with the Standard Operating Procedures of the process</li>
                        <li>On the job training on the process with a dedicated Mentor who is also a Subject Matter Expert on the domain</li>
                        <li>Progressive performance goals, focused on Quality and Efficiency that the employees must achieve.</li>
                    </ol>
                    <h6 className="themeTextRed">Multi-Skill Training:</h6>
                    Once an employee consistently delivers accurate and efficient work, he / she is trained on higher-skilled functions in the process or cross-trained on other functions to give him / her a better understanding of all functions and processes within the Organization. Their knowledge of the domain becomes stronger and they begin understanding the goals on a broader perspective, thus improving their ability to take important decisions.
                    <p style={{clear:"both"}}></p>

                    <h6 className="themeTextRed">Professional Development:</h6>
                    Once the employees become Subject Matter Experts at their respective domains, their people and process management skills are developed by the Organization. These are done through workshops and developmental training provided by the company’s management. They are given opportunities to sharpen these skills by mentoring their fellow team members and improving the overall performance of their processes.
                    <p style={{clear:"both"}}></p>

                    <h6 className="themeTextRed">Overseas Training:</h6>
                    Employees who have delivered exemplary performances, have excellent technical skills and are leaders of their respective domains are given an opportunity to be trained by the Clients in the US. The employees will be able to implement newer ideas and strengthen the processes for both the Clients and XL Dynamics India Pvt. Ltd., thereby contributing to the Organization’s stability and growth.
                    <div className="text-right">
                        <Nav.Link as={Link} to="/Careers"><Button variant="danger" size="large">&lt;&lt; Back to Careers</Button></Nav.Link>
                    </div>
                </Col>
                <Col xs={1}></Col>
            </Row>
        </React.Fragment>
    );
}

export default TandD;