import React, { Component} from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Jumbotron, Container, Form, Button, Modal, Image, Toast, Spinner, Nav, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CsvDownload from 'react-json-to-csv';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { MDBDataTable, MDBDataTableV5  } from 'mdbreact';
import axios from 'axios';
import { CSVLink } from "react-csv";

import JobBlock from '../../components/CreateOpeningBlock';
import ReactPaginate from 'react-paginate';

const TITLE = "CurrentOpenings - XL Dynamics Pvt. Ltd";

const loginEmail = 'admin'
const loginPassword = "xld!@#$"
class ReferEmployeeBack extends Component {
    constructor(props){
        super(props);
        this.state = {
            error : false,
            filterData: [],
            referEmpData : [],
            exportData: [],
            openingData: [],
            filterDateData: [],
            yearWiseData: [],
            openingCode : this.props.match.params.OpeningName,
            openingName: "",
            startDate: null,
            email:"",
            password:"",
            visiblePanel: false,
            selectedFile: null,
            showTY: false,
            active: false,
            offset: 0,
            filterText: null,
            pageCount:0,
            limit: 100,
            year:null,
            csvStatus: '',
            yearSelected: ''
        };
        this.getReferEmployeeData = this.getReferEmployeeData.bind(this)
        this.exportToCSV = this.exportToCSV.bind(this)
        this.handleEmailChange = this.handleEmailChange.bind(this)
        this.handlePasswordChange = this.handlePasswordChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.signOut = this.signOut.bind(this)
        this.refresh = this.refresh.bind(this)
        this.toggleMenu = this.toggleMenu.bind(this)
        this.filerbyText = this.filerbyText.bind(this)
        this.getFullcandidateData = this.getFullcandidateData.bind(this)
        this.fetchedDataInLoop = this.fetchedDataInLoop.bind(this);
        this.generateFullCSVData  = this.generateFullCSVData .bind(this);
    }
    
    componentDidMount() {
        this.getFullcandidateData()
        this.exportToCSV()
        this.getReferEmployeeData()
        this.getProfileInfo()
        if(localStorage.getItem('email') == loginEmail && localStorage.getItem('password') == loginPassword){
            this.setState({
                visiblePanel: true
            })
        }
        this.setState({
            error: false
        })
    }

    getReferEmployeeData(filterText=this.state.filterText){
        var myHeaders = new Headers();
        myHeaders.append('pragma', 'no-cache');
        myHeaders.append('cache-control', 'no-cache');
        myHeaders.append('Content-Type', 'application/json',);
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Access-Control-Allow-Headers", "*");
        myHeaders.append("Access-Control-Allow-Methods", "*");
        const referEmp = {
            "method": "get",
            headers: myHeaders,
            "limit": this.state.limit, 
            "offset": this.state.offset,
            "filterText": filterText 
        }
        axios.post(process.env.REACT_APP_REFERRAL_DATA_API, referEmp)
            .then((response) => {
                console.log(response.data);
                fetch(response.data.url, referEmp)
                .then( response => response.json())
                .then(
                    // handle the result
                    (result) => {
                        this.setState({
                            referEmpData : result,
                            filterData: result,
                            pageCount: result.length > 0 ? Math.ceil(result[0].totalRecords / this.state.limit) : 0
                        });
                    }
                )
            }, (error) => {
                console.log(error);
            });
            
    }

    exportToCSV(){
        var myHeaders = new Headers();
        myHeaders.append('pragma', 'no-cache');
        myHeaders.append('cache-control', 'no-cache');
        myHeaders.append('Content-Type', 'application/json',)
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Access-Control-Allow-Headers", "*");
        myHeaders.append("Access-Control-Allow-Methods", "*");
        const referEmp = {
            "method": "get",
            headers: myHeaders,
            "limit": this.state.limit, 
            "offset": this.state.offset,
            "filterText": null,
            "export": "full"
        }
        axios.post(process.env.REACT_APP_REFERRAL_DATA_API, referEmp)
            .then((response) => {
                console.log(response.data);
                fetch(response.data.url, referEmp)
                .then( response => response.json())
                .then(
                    // handle the result
                    (result) => {
                        this.setState({
                            exportData : result,
                            filterDateData: result
                        });
                    }
                )
            }, (error) => {
                console.log(error);
            });
    }

    getFullcandidateData(){
        fetch(process.env.REACT_APP_FULL_EMPLOYEE_DATA)
        .then( response => response.json())
        .then(
            // handle the result
            (result) => {
                this.setState({
                    exportData : result,
                    filterDateData: result
                });
            }
        )
    }

    getProfileInfo(){
        fetch(process.env.REACT_APP_CURRENT_OPENINGS_CDN_URL)
        .then( response => response.json())
        .then(
            // handle the result
            (result) => {
                this.setState({
                    openingData : result
                });
            }
        )
    }
    
    handleEmailChange(event) {
        this.setState({email: event.target.value});
    }

    handlePasswordChange(event) {
        this.setState({password: event.target.value});
    }
   
    handleSubmit(e) {
        e.preventDefault();
        localStorage.setItem('email', loginEmail)
        localStorage.setItem('password', loginPassword)
        if(loginEmail == this.state.email && loginPassword == this.state.password){
            this.setState({
                visiblePanel: true
            })
        } else {
            this.setState({
                error: true
            })
        }        
    }

    signOut() {
        localStorage.removeItem('email');
        localStorage.removeItem('password');
        this.setState({
            visiblePanel: false,
            error: false
        })
    }

    refresh() {
        window.location.reload(false);
    }

    uploadFile = event => {
        let file = event.target.files;
        console.log(file);
        this.setState({
            selectedFile: file
        })
        // setFileSelection(file); 
    }

    handleSubmitEMP = event => { 
        event.preventDefault();
        let file = this.state.selectedFile[0];
        axios("https://02tsbcu838.execute-api.us-east-1.amazonaws.com/default/getpresignedURL?file="+this.state.selectedFile[0].name)
        .then(response => {
            const urlNew = response.data.uploadURL;
            // setUrl(url)
            console.log("Recieved a signed request " + urlNew);

            var options = {
                headers: {
                'Content-Type': file.type
                }
            };
            axios({
                method: "PUT",
                url: urlNew,
                data: file,
                headers: { "Content-Type": "multipart/form-data" }
            })
            .then(result => {
                console.log("Response from s3", result)
                this.setState({
                    showTY: true
                })
            })
            .catch(error => {
                // alert("ERROR " + JSON.stringify(error));
                console.log("Put error status " +  JSON.stringify(error));
            })
        })
        .catch(error => {
            console.log(JSON.stringify(error));
        })
    }

    toggleMenu() {
        var toggle = this.state.active == false ? true : false;
          this.setState( {
            active: toggle
          });
    }

    handlePageClick = (data) => {
        let selected = data.selected;
        let offset = Math.ceil(selected * this.state.limit)+1;
    
        this.setState({ offset: offset }, () => {
          this.getReferEmployeeData();
        });
    };

    filerbyText = (event) => {
        console.log(event)
        this.state.filterText = event.target.value != "" ? event.target.value : null        
        this.getReferEmployeeData(this.state.filterText);
    };

    async fetchedDataInLoop (max, min, rep) {
        var myHeaders = new Headers();
        myHeaders.append('pragma', 'no-cache');
        myHeaders.append('cache-control', 'no-cache');
        myHeaders.append('Content-Type', 'application/json',)
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Access-Control-Allow-Headers", "*");
        myHeaders.append("Access-Control-Allow-Methods", "*");
        const referEmp = {
            "method": "get",
            headers: myHeaders,
            "max": max, 
            "min": min,
            "filterText": null,
            "action": 'getData'
        }
        //https://15lbefeprg.execute-api.us-west-1.amazonaws.com/prod/referral-candidate
        await axios.post(process.env.REACT_APP_REFERRAL_DATA_API, referEmp)
        .then(async (response) => {
            console.log(response.data);
            await fetch(response.data.url, referEmp)
            .then( response => response.json())
            .then(
                // handle the result
                (result) => {
                    var pushObject=this.state.yearWiseData;
                        result.filter((obj) => {
                            pushObject.push(obj);
                        })
                        this.setState({
                            yearWiseData: pushObject
                        });                 
                }, (error) => {
                    this.setState({
                        csvStatus: 'Error',
                    })  
                }
            )
        }, (error) => {
            console.log(error);
            this.setState({
                csvStatus: 'Error',
            })                  
        });
    }

    async generateFullCSVData(value){
        this.setState({
            csvStatus: 'REQUEST SENDING TO SERVER',
        })
        this.state.yearWiseData.length = 1          
        var myHeaders = new Headers();
        myHeaders.append('pragma', 'no-cache');
        myHeaders.append(-'cache-control', 'no-cache');
        myHeaders.append('Content-Type', 'application/json',)
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Access-Control-Allow-Headers", "*");
        myHeaders.append("Access-Control-Allow-Methods", "*");
        const referEmp = {
                "method": "get",
                headers: myHeaders,
                "limit": this.state.limit, 
                "offset": this.state.offset,
                "filterText": null,
                "action": 'count'
        }
        setTimeout(() => {
                this.setState({
                    csvStatus: 'RECEIVING DATA FROM API'
                });
        }, 2000)            
        //https://15lbefeprg.execute-api.us-west-1.amazonaws.com/prod/referral-candidate
        await axios.post(process.env.REACT_APP_REFERRAL_DATA_API, referEmp)
            .then(async (response) => {
                    console.log(response.data);                    
                    console.log('---------------------', response.data.count)
                    var objLength = response.data.count;
                    var i = objLength;
                    var j = 0;
                    var limit = 0;
                    var offset = 0;
                    var divideValue = 100000;
                    if(objLength > divideValue) {
                        while(i > divideValue){
                            j > 0 ? offset = limit+1 : offset = offset
                            limit = limit + divideValue;
                            j = j+1;
                            var minTime = 2000;
                            await this.fetchedDataInLoop(limit, offset, j)
                            i = i-divideValue;
                            minTime = minTime+100;
                            if(i<divideValue){
                                offset = limit+1
                                limit = offset+i;
                                await this.fetchedDataInLoop(limit, offset, j)
                                this.setState({
                                    year: 2019,
                                    csvStatus: 'PREPERING CSV'
                                });
                            }
                        }
                    } else {
                        var j = 0;
                        await this.fetchedDataInLoop(limit, offset, j)
                        this.setState({
                            year: 2019,
                            csvStatus: 'PREPERING CSV'
                        });
                    }                    
                }, (error) => {
                    console.log(error);                   
        });
    }

    render() {
        const columns = [
            {
                label: 'Date',
                field: 'date',
                sort: 'desc',
                width: 150
              },
              {
                label: 'Referred by Emp ID',
                field: 'emp',
                // sort: 'desc',
                width: 150
              },
              {
                label: 'Candidate Name',
                field: 'firstName',
                // sort: 'desc',
                width: 150
              },
              {
                label: 'Last Name',
                field: 'lastName',
                // sort: 'desc',
                width: 150
              },
              {
                label: 'Candidate Email',
                field: 'email',
                // sort: 'desc',
                width: 150
              },
              {
                label: 'contact Number',
                field: 'contactNumber',
                // sort: 'desc',
                width: 150
              },
              {
                label: 'city',
                field: 'city',
                // sort: 'desc',
                width: 150
              },
              {
                label: 'Applied Position',
                field: 'jobSelect',
                // sort: 'desc',
                width: 150
              },
              {
                label: 'Resume link',
                field: 'resume',
                // sort: 'desc',
                width: 150
              }             
        ]
        const {referEmpData, openingData, startDate, offset, visiblePanel, error, showTY, active, year, yearSelected, csvStatus, pageCount, exportData, filterDateData, yearWiseData} = this.state;
        const filterDataList = {columns: columns ,
            rows: filterDateData}
        var newOffset = offset
        newOffset = newOffset == 0 ? newOffset+1 : newOffset;
        const openingsList = openingData.map(oData => {
            let title = oData.openingTitle;
            if(oData.visible == 1){
                return <option key={oData.openingId} value={oData.openingTitle}>{title}</option>;
            }
        });
        return(
            <div className="table-responsive text-nowrap p-5" >
                <div className={"row col-md-12 " + (visiblePanel ? ' d-block' : ' d-none')}>   
                    <p className="text-right">
                        <button className="btn btn-primary" type="button" onClick={this.toggleMenu}>
                        Upload Employee info & College Data
                        </button>
                    </p>
                    <div className={active? 'd-block' : 'd-none'}>
                    <div className="jumbotron card card-image container-fluid p-3">
                        <h2 className="text-center pb-3">Welcome message for students & information for job seeker</h2>
                        <div className="row make-center border p-3">
                            <Form id="applyNow" className="form-inline" encType="multipart/form-data" onSubmit={this.handleSubmitEMP} method="POST" autoComplete="off">
                                <div className="col-xs-12 form-group mr-2"><h4>Upload Employee info & College Data: </h4> </div>
                                <div className="col-xs-12 form-group mr-2">
                                    <span>
                                    <input type="file"
                                    name="myFile"
                                    onChange={this.uploadFile} required/>
                                    </span>
                                </div>
                                <div className="col-xs-12 form-group mr-2">
                                    <Button variant="danger" type="btn btn-lg submit">Upload</Button>
                                </div>
                            </Form>
                        </div>
                        <Modal size='md' centered show={showTY} backdrop="static">
                            <Modal.Body>Thanks, File uploded Successfully. </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={this.refresh}>Close</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
                </div>    
                    <h2 className="text-center pb-2">List of Employees Reference</h2>
                    <div className={"row" + (visiblePanel ? ' d-none ' : ' d-block')}>
                        <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                        {
                            error ? 
                            <div className="alert alert-danger" role="alert">
                                Please login with valid username and password 
                            </div> : ''
                        }
                        
                            <div className="card card-signin my-5">
                                <div className="card-body">
                                    <h5 className="card-title text-center">Sign In</h5>
                                    <form className="form-signin" onSubmit={this.handleSubmit}>
                                    <div className="form-label-group">
                                        <input type="text" id="email" className="form-control" value={this.state.email} onChange={this.handleEmailChange} placeholder="Email address" required autofocus />
                                        <label for="email">User Name</label>
                                    </div>

                                    <div className="form-label-group">
                                        <input type="password" id="password" className="form-control" value={this.state.password} onChange={this.handlePasswordChange} placeholder="Password" required />
                                        <label for="password">Password</label>
                                    </div>                                
                                    <button className="btn btn-lg btn-danger btn-block text-uppercase" type="submit">Sign in</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        </div>

                    <div className={"row col-md-12 " + (visiblePanel ? ' d-block' : ' d-none')}>                        
                        {/* <div className=" row make-center border pt-3 pr-3 mb-5 mt-3"> */}
                            <div className="row d-block button-panel p-2 mb-5 mr-1 mt-3">
                                {/* <button className="btn btn-md btn-warning btn-block text-uppercase" type="button" style={{left:"0"}} onClick={this.refresh}>Refresh</button> */}
                                <CSVLink 
                                    data={exportData} 
                                    filename={"candidateData.csv"}
                                    style={{ //pass other props, like styles
                                        backgroundColor: "#28a745",
                                        cursor: "pointer",
                                        fontSize:"15px",
                                        fontWeight: "bold",
                                        display: "block",
                                        border: "1px solid #1c7430",
                                        color: "#ffffff",
                                        padding: "5px",
                                        borderRadius: "5px",
                                        position: "absolute",
                                        right:"120px",
                                        display:"none"
                                    }}
                                    className={exportData.length > 0 ? "text-center" : "disabled"}>
                                    {exportData.length > 0 ? 'EXPORT ALL DATA TO CSV' : <>GENERATING FULL CSV PLEASE WAIT....<Spinner animation="grow" variant="warning" /></>}
                                </CSVLink>

                                <button className="btn btn-md btn-danger btn-block text-uppercase" type="button" onClick={this.signOut}>Sign out</button>
                                
                            </div>
                        {/* </div> */}
                        <div className="export-panel border p-2 mb-2">
                            <table>
                                <tr>
                                    <td>
                                        <button  className={yearWiseData.length <= 0 ? "btn btn-md btn-success btn-block bold text-uppercase" : "display-none"} type="button" onClick={this.generateFullCSVData}>Click to Generate CSV for download</button>
                                    </td>
                                    <td>
                                        { year != null ?
                                            <CSVLink 
                                                data={yearWiseData} 
                                                filename={"candidateData.csv"}
                                                style={{ //pass other props, like styles
                                                    backgroundColor: "#28a745",
                                                    cursor: "pointer",
                                                    fontSize:"15px",
                                                    fontWeight: "bold",
                                                    // margin: "0 10px",
                                                    display: "block",
                                                    // border: "1px solid #1c7430",
                                                    color: "#ffffff",
                                                    padding: "8px",
                                                    borderRadius: "5px",
                                                    // position: "absolute",
                                                    // right:"120px"
                                                }}
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        this.setState({
                                                            // yearWiseData:[],
                                                            // year: null,
                                                            csvStatus: '',
                                                            yearSelected:''
                                                        })
                                                    }, 2000)                                                    ;
                                                  }}
                                                className={yearWiseData.length > 0 ? "text-center" : "disabled year-btn"}>
                                                {yearWiseData.length > 0 ? 'DOWNLOAD TO CSV' : <>GENERATING....<Spinner animation="grow" variant="warning" /></>}
                                            </CSVLink>
                                        : yearWiseData.length != 0 && <><span>{csvStatus}....</span> {csvStatus == 'Error' ? <span>Error while fetching data <a onClick={this.refresh}>try again </a> </span> : <Spinner animation="border" variant="warning" /> } </>  
                                        }
                                    </td>
                                </tr>
                            </table>                            
                        </div>
                        
                        <div className=" row col-md-12 make-center border p-2 mb-2 mt-5">
                            <input type="text" id="txtFilter" placeholder="Search with anything like Emp ID, Candidate name, Email, Applied Position, city, Contact Number etc .... Enter value and click outside to search" className="form-control" onBlur={this.filerbyText}></input>
                            {/* <span>Show entries</span> */}
                        </div>
                       
                        
                        {this.state.startDate != null ? '' :
                        referEmpData.length == 0 ?
                        <div style={{textAlign:"center"}}>                        
                            <Spinner animation="grow" variant="primary" />
                            <Spinner animation="grow" variant="secondary" />
                            <Spinner animation="grow" variant="success" />
                            <Spinner animation="grow" variant="danger" />
                            <Spinner animation="grow" variant="warning" />
                            <Spinner animation="grow" variant="info" />
                            <Spinner animation="grow" variant="light" />
                        </div>
                        :
                        <>
                        <p><strong>Total Records: {referEmpData[0].totalRecords}</strong></p>
                        <table className="table table-striped w-auto" style={{margin:"0 auto", minWidth:"100%", fontSize:"15px"}}>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th className="th-lg">Date</th>
                                <th className="th-lg">Ref by Emp ID</th>
                                <th className="th-lg">Candidate Name</th>
                                <th className="th-lg">Candidate Email</th>
                                <th className="th-lg">contact Number</th>
                                <th className="th-lg">city</th>
                                <th className="th-lg">Applied Position</th>
                                <th className="th-lg">Resume</th>
                            </tr>
                            </thead>
                            <tbody>
                                {
                                referEmpData.map((employee, index) => 
                                    <tr>
                                        <th scope="row">{newOffset + index}</th>
                                        <td>{employee.date}</td>
                                        <td>{employee.emp}</td>
                                        <td>{employee.firstName}&nbsp;{employee.lastName}</td>
                                        <td>{employee.email}</td>
                                        <td>{employee.contactNumber}</td>
                                        <td>{employee.city}</td>
                                        <td>{employee.jobSelect}</td>
                                        <td><a href={employee.resume} target="_blank">Download</a> </td>
                                    </tr>
                                )

                                }
                        
                            
                            </tbody>

                        </table>
                        {
                            this.state.startDate == null  ?                         
                            <ReactPaginate
                                previousLabel={'previous'}
                                nextLabel={'next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                            /> : ''}
                        </>
                        }
                        
                        {
                        this.state.startDate != null ?
                        filterDataList.rows.length == 0 ? 
                                <div style={{textAlign:"center"}}>                        
                                <Spinner animation="grow" variant="primary" />
                                <Spinner animation="grow" variant="secondary" />
                                <Spinner animation="grow" variant="success" />
                                <Spinner animation="grow" variant="danger" />
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="info" />
                                <Spinner animation="grow" variant="light" />
                                </div>
                                :
                                <MDBDataTable
                                striped
                                bordered
                                small
                                entries={20}
                                entriesOptions={[10, 20, 40, 80, 100, 200, 300]}
                                data={filterDataList}
                                hover
                                searchLabel="Search with anything like Emp ID, Candidate name, email, positions, city etc"
                                />
                            : ''
                        }
                    
                     </div>
                </div>
        );
    }
}

export default ReferEmployeeBack;