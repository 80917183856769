import Carousel from 'react-bootstrap/Carousel'
import React, { useState, useEffect  } from 'react';

function ReferralCarousel() {
    const [index, setIndex] = useState(0);
    const bannerImages = [
        // {src: require('../../images/banners/new-year-giveaway.jpg'), href: "https://www.linkedin.com/posts/xl-dynamics-india-pvt%2E-ltd%2E_xldmytruehome-xldmytruehome-xldynamics-activity-6879671521460178944-QYnC"},           
        {src: require('../../images/banners/Banner1.jpg'), href: "#"},
        {src: require('../../images/banners/Banner2.jpg'), href: "#"},
        {src: require('../../images/banners/Banner3.jpg'), href: "#"}
    ];  
    return (
        <Carousel indicators={false}>
            {
                bannerImages.map((item) => {
                    return <Carousel.Item>
                        <a href={item.href} target="_blank">
                        <img
                        className="d-block w-100"
                        src={item.src}
                        alt="First slide"
                        /> 
                        </a>      
                  </Carousel.Item>
                  
                })
            }
        
      </Carousel>
    );
}
  
export default ReferralCarousel;