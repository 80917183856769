// const {Component} = React;
// const {render} = ReactDOM;
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col } from 'react-bootstrap';
import ReactCssTransitionGroup  from 'react-addons-css-transition-group'
// const ReactCssTransitionGroup = React.addons.CSSTransitionGroup;
import '../css/tabs.css';
import {peopleVideo} from './Includes/meetPeople'
import ControlledCarousel from './carousel'

const TITLE = "Meet our people - XL Dynamics India Pvt. Ltd";

const items = [
  {category: "OPERATIONS", color:"#eba8b0"},
  {category: "IT", color:"#73bfff"},
  {category: "SUPPORT", color:"#8dd6cd"},
  {category: "SERVICING", color:"#73bfff"},
  {category: "WOMAN AT XLD", color:"#8dd6cd"},
]

class MeetOurPeople extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0
    }
  }
  
  setSelected(e, index){
    e.preventDefault();
    this.setState({selected: index});
  }

  renderItems(){
    return  peopleVideo.map( (item, key)=>{
        if(key !== this.state.selected) return
        return (
          <div className={"item col-12" +(item.category == 'WOMEN AT XLD' ? ' woman' : '')}
        // <div className="item col-12"
            key={`peopleVideo-${key}`}
            style={{backgroundColor:item.color}}>
                {
                    item.info.map((emp) =>{
                        return(
                            <div className="video-block col-sm-4 col-md-4 col-lg-2">
                               <a href={emp.video} target="_blank"> <img src={emp.src} /> </a>
                                <strong>{item.category != 'WOMEN AT XLD' ? emp.employee : ''}</strong>
                                <span>{item.category != 'WOMEN AT XLD' ? emp.team : ''}</span>
                            </div>
                        )
                    })
                }
          </div>
        );
      })
  }
  render(){
    return(
        <React.Fragment>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Row className="removeMarginLR meet-our-people">
                <Col xs={1}></Col>
                <Col xs={12} md={10} sm={12}>
                    <div className="x-head" >MEET OUR <span style={{color:"#bb0909"}}>PEOPLE</span></div>

                    <div className="app">
                        <div className="nav">
                        {peopleVideo.map((item, key)=>{
                            const activeClass = key === this.state.selected ? 'active' : '';
                            return(
                            <a className={`${activeClass} select`} 
                                key={item.color} 
                                onClick={e=>this.setSelected(e, key)}>
                                <span>{item.category}</span>
                            </a>
                            )
                        })}
                        </div>
                        <div className="items">
                          <ReactCssTransitionGroup
                              transitionName="fade"
                              transitionEnterTimeout={500}
                              transitionLeaveTimeout={500}>
                              {this.renderItems()}
                          </ReactCssTransitionGroup>
                        </div>
                    </div>

                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row className="text-center make-center slider-panel">
              <ControlledCarousel/>
            </Row>
        </React.Fragment>
    )
  }
}

// render(<App />, document.getElementById('root'))

export default MeetOurPeople;