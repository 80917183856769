import React from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Image } from 'react-bootstrap';

import FucntionsBanner from '../images/banners/Functions-banner.jpg';

const TITLE = "Functions - XL Dynamics India Pvt. Ltd"

function Functions() {
    return(
        <React.Fragment>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Row className="removeMarginLR">
                <Image className="d-block w-100" style={{padding:"10px"}} src={FucntionsBanner} fluid />
                <Col xs={1}></Col>
                <Col xs={10}>
                    <h1 className="x-head" style={{height: "50px"}}>Our Functions</h1>
                    <p>We have proven expertise to provide comprehensive solutions to a defined clientele in the US mortgage industry, including but not limited to:</p>
                    <ul>
                        <li>IT solutions</li>
                        <li>Expert analysis, assessment and audit of complex financial products</li>
                        <li>Inspection of executed financial trades</li>
                        <li>Documentation of policies, processes and guidelines</li>
                        <li>Quality control services, Operations and Servicing quality control</li>
                        <li>Trading and hedging strategy and support</li>
                        <li>Regulatory compliance and audit services</li>
                        <li>Securitization of loan instruments study</li>
                    </ul>
                    <h4>Financial Analysts:</h4>
                    <p>A Financial Analyst is responsible to complete accurate data analysis while maintaining the integrity, security and privacy of the data. They are specialists at XL Dynamics, who accurately set up the loans in the ERP system after reviewing the loan files. They carry out checks and verifications to ascertain the regulatory compliance of the loan. They perform verifications as per the process checklist to determine authenticity of data and submit their analysis to our clients in US.</p>
                </Col>
                <Col xs={1}></Col>
            </Row>
        </React.Fragment>
    );
}

export default Functions;