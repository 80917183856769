import React from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Image } from 'react-bootstrap';
import LeadershipBanner from '../images/banners/Leadership-banner.jpg';

const TITLE = "Leadership - XL Dynamics India Pvt. Ltd"

function Leadership() {
    return(
        <React.Fragment>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Row className="removeMarginLR">
                <Image className="d-block w-100" style={{padding:"10px"}} src={LeadershipBanner} fluid />
                <Col xs={1}></Col>
                <Col xs={10} style={{textAlign:"justify"}}>
                    <h1 className="x-head" style={{height: "50px"}}>Leadership &#64; XLD</h1>
                    <p>Leaders at XL Dynamics understand and inculcate our culture and values in every employee that they manage. Leaders believe in the Corporate Mission Statement and the purpose for which the Organization was formed. They understand the shift in goals from time to time as the Organization grows and adapt to deliver them as expected.</p>
                    <p>Leaders aren&rsquo;t born, but are made. What are the qualities that make a Good Leader at XL Dynamics?</p>
                    
                    <h6 className="themeTextRed">Passionate about Excellence in Customer Service:</h6>
                    <p>The sole purpose of the existence of a Leader in XL Dynamics is to passionately serve the customer.</p>
                    <p style={{clear:"both"}}></p>
                    <p>Leaders in XL Dynamics are very passionate in delivering nothing less than excellent customer service to our Clients and Stakeholders. They possess the ability to channel the efforts of all their teams of bright and highly intellectual individuals, to achieve the Client&rsquo;s objectives.</p>
                    <p style={{clear:"both"}}></p>
                    <p>They follow &ldquo;Zero Tolerance for Errors&rdquo; within the Teams they Lead or Supervise. The only rating they accept on the delivered Quality of Work is an A+.</p>
                    <p style={{clear:"both"}}></p>
                    
                    <h6 className="themeTextRed">Assertive:</h6>
                    <p>Leaders at XL Dynamics are strong-willed, self disciplined, have the ability to work on any task assigned to them, whether or not the task is related to their domain knowledge. All Leaders at XL Dynamics are promoted from within the Organization.</p>
                    <p style={{clear:"both"}}></p>
                    <p>No-one gets the better of the XL Dynamics Leader. Our Leaders are strong in their beliefs and set the right expectations from their employees. Our Leaders appreciate Individual Efforts and reward excellence. They also condone substandard quality or dishonesty.</p>
                    <p style={{clear:"both"}}></p>
                    
                    <h6 className="themeTextRed">Business Leaders are Business Owners:</h6>
                    <p>Our Leaders run their respective Units like a Business Owner. They are closely connected with the day-to-day functioning of the Process, set expectations and drive teams towards achieving them.</p>
                    <p style={{clear:"both"}}></p>
                    <p>They ensure that through constant innovation and reengineering of processes, the Unit&rsquo;s production, quality and profitability consistently improve, making the Unit more efficient and cost-effective. This allows the leaders to obtain more Business for the Unit and the Organization at large.</p>
                    <p style={{clear:"both"}}></p>
                    <p>They invest themselves completely in their Business. They are hence able to think quickly and strategize to ensure that minimal efforts or losses are incurred. Business Leaders thus are Business Owners here at XL Dynamics.</p>
                    <p style={{clear:"both"}}></p>
                    
                    <h6 className="themeTextRed">Innovative decision makers and solution oriented:</h6>
                    <p>Business Leaders at XL Dynamics are empowered with the authority to take complex and important decisions on behalf of the Organization and the Employees. Business Leaders do not just look for issues in the process but rather find innovative solutions to permanently fix them, in a short period of time.</p>
                    <p style={{clear:"both"}}></p>
                    <p>Every moment is important and time lost causes delay in delivering services to Clients. Effective and Solution Oriented decision making is thus a very important attribute of our Leadership Training. After all, &ldquo;Time costs Money.&rdquo;</p>
                    <p style={{clear:"both"}}></p>
                    
                    <h6 className="themeTextRed">Achieve objectives with Prudent use of resources:</h6>
                    <p>Through effective decision-making, planning, passion for excellence and customer service, and running the Business Unit like an Owner, every Business Leader at XL Dynamics is trained to manage all functions with prudent use of resources, both &ldquo;Human&rdquo; and &ldquo;Technology&rdquo;.</p>
                    <p style={{clear:"both"}}></p>
                    <p>XL Dynamics is thus able to offer profitable services to our Clients at competitive pricing as our Business Processes operate at optimal levels.</p>
                    <p style={{clear:"both"}}></p>
                    <p>Re-engineering and innovation are also instrumental in making functions more productive and efficient. &ldquo;Zero tolerance to errors&rdquo; ensures no wastage of resources and efforts on corrections and audits since every leader inculcates the &ldquo;Do it right the first time.&rdquo; philosophy with every employee.</p>
                    <p style={{clear:"both"}}></p>
                    
                    <h6 className="themeTextRed">Micro Managers:</h6>
                    <p>By being Micro Managers, Business Leaders at XL Dynamics are connected with the Pulse of the Process and the Employees. Every Business Leader completes a certain level of production every month on his / her own. This sharpens the Leader&rsquo;s knowledge of the process and gives him / her firsthand experience of the process and feedback on areas of improvement in the process.</p>
                    <p style={{clear:"both"}}></p>
                    <p>This helps in timely identification of issues related to Process, People or Technology on a real-time basis and they are fixed before they become harmful to the organization.</p>
                    <p style={{clear:"both"}}></p>
                    <p>Micro Managing also helps when re-engineering and innovating strategies to make the process robust and efficient.</p>
                    <p style={{clear:"both"}}></p>
                </Col>
                <Col xs={1}></Col>
            </Row>
        </React.Fragment>
    );
}

export default Leadership;