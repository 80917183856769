import React from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Carousel, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import CareerBanner from '../images/banners/Careers-banner.jpg';
// import SpecialBanner from '../images/banners/openings/usMortSpl-banner.jpg';

const TITLE = "Careers - XL Dynamics India Pvt. Ltd"

function Careers() {
    return(
        <React.Fragment>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            {/* <Carousel style={{padding:"10px"}} indicators={false} nextIcon={false} prevIcon={false}>
                <Carousel.Item>
                    <img className="d-block w-100" src={CareerBanner} alt="" />
                </Carousel.Item>
            </Carousel><br /> */}
            <Row className="removeMarginLR">
                <Col xs={1}></Col>
                <Col xs={10}>
                    <h1 className="x-head" style={{height: "50px"}}>Careers</h1>
                    <p>We know that attracting, developing and retaining talented and motivated individuals is at the heart of delivering superior results to our clients and retaining them by delivering on our promises is vital to our continued success. Our ever expanding business provides limitless opportunities for you to put your knowledge and technical abilities to work, but that’s just the beginning. We want to develop, leverage and unlock the talent in every individual who works for XLD.</p>
                    <p>To have some more insights on our career options, kindly choose from the following:</p>
                    <Row className="text-center">
                        <Col xs={12} sm={4} md={4} className="pb-3">
                            <Nav.Link as={Link} to="/current-openings" className="btn btn-danger btn-large">Current Openings</Nav.Link>
                        </Col>
                        <Col xs={12} sm={4} md={4} className="pb-3">
                            <Nav.Link as={Link} to="/training-and-development" className="btn btn-danger btn-large">Training and Development</Nav.Link>
                        </Col>
                        <Col xs={12} sm={4} md={4} className="pb-3">
                            <Nav.Link as={Link} to="/why-choose-xldynamics" className="btn btn-danger btn-large">Why choose XL Dynamics?</Nav.Link>
                        </Col>
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>
        </React.Fragment>
    );
}

export default Careers;