import React from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Image, Card, CardDeck } from 'react-bootstrap';

// Image import
import ContactUsBanner from '../images/banners/Contact-Us-banner.jpg'
import HrResources from '../images/hr_resources.png';
import Offers from '../images/offers.png';
import VOE from '../images/VOE.png';

const TITLE = "ContactUs - XL Dynamics India Pvt. Ltd"

function ContactUs() {
    return(
        <React.Fragment>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Row className="removeMarginLR">
                <Image className="d-block w-100" style={{padding:"10px"}} src={ContactUsBanner} fluid />
                <Col xs={1}></Col>
                <Col>
                    <h1 className="x-head" style={{height: "50px"}}>Contact Us</h1>
                    <p>XL Dynamics India Pvt. Ltd. is headquartered at Millennium Business Park in the planned satellite township of Mumbai, Navi Mumbai. 
                        Millennium Business Park (MBP) is a government owned IT park and is one of the biggest industrial hubs in the Navi Mumbai Area. 
                        Millennium Business Park (MBP) is well connected to major cities of Mumbai, Thane, satellite township - Navi Mumbai and other suburbs. 
                        XL Dynamics has several office locations in Millenium Business Park.</p>
                    <Row>
                        <Col className="text-center">
                            <Card.Body>
                                <h4 className="themeTextRed">Corporate Headquarters:</h4>
                                Block No. 301/302<br/>
                                Building V, Sector II<br/>
                                Millennium Business Park, Mahape,<br/>
                                Navi Mumbai, Maharashtra - 400 710<br/> 
                                <strong>Phone</strong>: <a href="tel:+912227781211">+91-22-27781211</a><br/>
                                <strong>Email</strong>: <a href="mailto:contact@xldynamics.co.in">contact@xldynamics.co.in</a><br/>
                            </Card.Body>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Card border="danger">
                                <Card.Body>
                                    <iframe title="xldAddress" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d942.481455395936!2d73.01578168220901!3d19.110909852599093!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c0e7e7fdb76f%3A0xfdfdc82c808b7613!2sXL+Dynamics+India+Private+Limited!5e0!3m2!1sen!2sus!4v1539252768889" frameBorder="0" style={{width:'100%',height:'300px'}}></iframe>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row><br/>
                    <CardDeck>
                        <Card border="danger">
                            <Card.Img varient="top" src={HrResources} />
                            <Card.Body>
                                To contact human resources, please email <a href="mailto:hrindia@xldynamics.com">hrindia@xldynamics.com</a>
                            </Card.Body>
                        </Card>
                        <Card border="danger">
                            <Card.Img varient="top" src={VOE} />
                            <Card.Body>
                                To request verification of employment, send an email to <a href="mailto:voe@xldynamics.com">voe@xldynamics.com</a>
                            </Card.Body>
                        </Card>
                        <Card border="danger">
                            <Card.Img varient="top" src={Offers} />
                            <Card.Body>
                            If you have 100+ qualified and aspiring candidates who wish to make a career in the financial sector then we would love to arrange a recruitment drive at your location.
                            To know more, please email us at <a href="mailto:campus.recruitment@xldynamics.com">campus.recruitment@xldynamics.com</a>
                            </Card.Body>
                        </Card>
                    </CardDeck>
                </Col>
                <Col xs={1}></Col>
            </Row>
        </React.Fragment>
    );
}

export default ContactUs;