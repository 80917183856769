import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Nav, Image, Card, Modal, Button} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import ApplyNow from '../ApplyNow';
import CareerBanner from '../../images/banners/CurrentOpenings1-banner.jpg';
import referBanner from '../../images/banners/Header.jpg';
import RecruitmentCarousel from '../Openings/recruitmentCarousel';
import insideLook from '../../images/Culture/An-Inside-look.png';
import emoji1 from '../../images/Culture/Emoji1.png';
import emoji2 from '../../images/Culture/Emoji2.png';


const TITLE = "Job Application - XL Dynamics India Pvt. Ltd"
var empID = ""

class Recruitment extends Component {
    constructor(props){
        super(props);
        this.state = {
            error : null,
            isLoaded : false,
            openingData : [],
            openingCode : this.props.match.params.OpeningName,
            openingName: "",
            params : {},
            show: false,
            filterData: []
        };
        this.getParams = this.getParams.bind(this)
    }
    
    componentDidMount() {
        fetch(process.env.REACT_APP_CURRENT_OPENINGS_CDN_URL)
        .then( response => response.json())
        .then(
            // handle the result
            (result) => {
                this.setState({
                    isLoaded : true,
                    openingData : result
                });
            }
        )
        empID = this.props.location.pathname == "/dolphins" ? "Dolphins" : this.getParams(window.location.href)
        if(this.props.location.pathname == "/dolphins"){
            document.body.classList.add("refer-class")
        } 
        console.log('*********************', this.props)       
        
    }

    getParams(url) {
        var params = {};
        var parser = document.createElement('a');
        parser.href = url;
        var query = parser.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            params[pair[0]] = decodeURIComponent(pair[1]);
        }

        return params;
    };
    
    selectChange(id, event){
        this.state.openingData.map((key, data) =>{
            if(key.openingId == id){
                this.setState({
                    show: true,
                    filterData: key
                })
            }
        })       
    }

    handleClose(){
        this.setState({
            show: false
        })
    }

    handleShow(){
        this.setState({
            show: true
        })
    }

    render() {
        // const {
        //     params: {openingCode}
        // } = match;
        const {openingData, show, filterData} = this.state;
        const jobDetails = openingData.map(Openings => {
            if(Openings.openingId === filterData.openingId) {                
                return <div key={Openings.openingId}>                                         
                    <ul>
                        <li className="hide-list"><span style={{display: "inline"}} className="themeTextRed"><strong>Position:</strong> </span><span dangerouslySetInnerHTML={{__html: Openings.openingTitle}}></span></li>
                        {Openings.shiftTiming==="" ? "" : <li><span style={{display: "inline"}} className="themeTextRed"><strong>Job Shift:</strong> </span><span dangerouslySetInnerHTML={{__html: Openings.shiftTiming}}></span></li>}
                        {Openings.experience==="" ? "" : <li><span style={{display: "inline"}} className="themeTextRed"><strong>Experience:</strong> </span><span dangerouslySetInnerHTML={{__html: Openings.experience}}></span></li>}
                        {Openings.education==="" ? "" : <li><span style={{display: "inline"}} className="themeTextRed"><strong>Education:</strong> </span>
                        {/* <span dangerouslySetInnerHTML={{__html: Openings.education}}></span> */}
                        <span>We hire based on individual talent, skill and work ethic. Formal degrees are not a material in our hiring decision.</span>
                        </li>}
                        {Openings.jobLocation==="" ? "" : <li><span style={{display: "inline"}} className="themeTextRed"><strong>Job Location:</strong> </span><span dangerouslySetInnerHTML={{__html: Openings.jobLocation}}></span></li>}
                        {Openings.salaryRange==="" ? "" : <li><span style={{display: "inline"}} className="themeTextRed"><strong> {filterData.openingId == 4 || filterData.openingId == 22 ? "Salary" : "Salary Range"}:</strong> </span>
                        {filterData.openingId == 4 || filterData.openingId == 22 ? "Salary: Best in the industry (Night shift allowance of Rs 1,00,000, in addition to the CTC)" : <span dangerouslySetInnerHTML={{__html: Openings.salaryRange}}></span>}</li>}
                        {Openings.daysOfWalkIn==="" ? "" : <li><span style={{display: "inline"}} className="themeTextRed"><strong>Days of Walk-in:</strong> </span><span dangerouslySetInnerHTML={{__html: Openings.daysOfWalkIn}}></span></li>}
                        {Openings.timeOfWalkIn==="" ? "" : <li><span style={{display: "inline"}} className="themeTextRed"><strong>Time for Walk-in:</strong> </span><span dangerouslySetInnerHTML={{__html: Openings.timeOfWalkIn}}></span></li>}
                        
                        {this.props.location.search.length > 0 ? <li><span style={{display: "inline"}} className="themeTextRed"><strong>Key Responsibilities:</strong></span> <div dangerouslySetInnerHTML={{__html: Openings.keyResponsibilities}}></div></li> : ''}
                        {/* {this.props.location.search.length > 0 ? <li><span style={{display: "inline"}} className="themeTextRed"><strong>SKILLS/EXPERTISE:</strong></span> <div dangerouslySetInnerHTML={{__html: Openings.skillsNExpertise}}></div></li> : ''} */}
                    </ul>                    
                    <Card.Body fluid="true" className="customBorder hide-refer">
                            <span style={{display: "inline"}} className="themeTextRed"><strong>Key Responsibilities:</strong> </span>
                            <div dangerouslySetInnerHTML={{__html: Openings.keyResponsibilities}}></div>
                    </Card.Body>                                                          
                    <Card.Body fluid="true">
                        <span style={{display: "inline"}} className="themeTextRed"><strong>Note:</strong> </span>
                        <div dangerouslySetInnerHTML={{__html: Openings.notes}}></div>
                    </Card.Body><br/>
                </div>;
            }
        });
        console.log(openingData)
        return(
            <React.Fragment>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
                <Row className="removeMarginLR">
                    {
                        this.props.location.pathname == "/dolphins" ? 
                        // <Image className="d-block w-100" style={{padding:"10px"}} src={referBanner} fluid /> 
                        <RecruitmentCarousel/>
                        : 
                        // <ReferralCarousel/>
                        <Image className="d-block w-100" style={{padding:"10px"}} src={CareerBanner} fluid />
                    }
                    <Col xs={1}></Col>
                    <Col xs={12} sm={12} md={10}>
                        
                            <div className="refer-container">                                 
                                <div className="about-title rec-title">
                                    <p>
                                    <strong>At XL Dynamics, you are always in good company.</strong> You’ll be empowered to follow your passion and
                                    be treated with <strong>dignity</strong>  and <strong> respect</strong> . Your ideas and opinions will be<strong> valued</strong>. We trust our people to
                                    do amazing things; and they have always delivered. This is in stark contrast to how YOUR
                                    EMPLOYER, BETTER.COM, treats you.                                        
                                    </p>
                                </div>
                            </div> 
                            <div className="refer-container">
                                <Row>
                                <Col xs={12} sm={12} md={6}>
                                    <div className="card card-left">
                                    <div className="card-header">
                                        How clients treat XLD’ites
                                    </div>
                                    <div class="card-body card-body-panel">
                                        <p class="card-text ">
                                        “Everyday, I’m humbled by the hard work and
                                        innovation at XL Dynamics. Everyday my pride and
                                        respect for this Team rises to a new high. You are
                                        <span> wonderful </span> and I<span> couldn’t be more grateful</span> to work
                                        alongside each of you.”<img src={emoji1}></img>
                                        
                                        <p className="text-right">- Pavan Agarwal, CEO of Sun West Mortgage</p>
                                        </p>
                                    </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6}>
                                    <div className="card card-right">
                                    <div className="card-header">
                                        Better.com
                                    </div>
                                    <div class="card-body card-body-panel">
                                        <p class="card-text">
                                            “HELLO — WAKE UP TEAM,”...“You are TOO DAMN
                                            SLOW. You are a bunch of <span> DUMB DOLPHINS </span>
                                            and...DUMB DOLPHINS get caught in nets and eaten
                                            by sharks. SO STOP IT. STOP IT. STOP IT RIGHT
                                            NOW.<span> YOU ARE EMBARRASSING ME.</span>”<img src={emoji2}></img>

                                            <p className="text-right">
                                            - Vishal Garg, CEO of Better.com, in an email to<br></br>
                                            employees obtained by <a href="https://www.forbes.com/sites/davidjeans/2020/11/20/mortgages-fraud-claims-and-dumb-dolphins-a-tangled-past-haunts-bettercom-ceo-vishal-garg/?sh=553f35aa10f4" target="_blank">Forbes</a> (as cited in the article<br></br>
                                           <a href="https://www.forbes.com/sites/davidjeans/2020/11/20/mortgages-fraud-claims-and-dumb-dolphins-a-tangled-past-haunts-bettercom-ceo-vishal-garg/?sh=553f35aa10f4" target="_blank"> Mortgages, Fraud Claims and ‘Dumb Dolphins’</a>)
                                            </p>
                                        </p>
                                    </div>
                                    </div>    
                                </Col>                                    
                                </Row>
                                <div className="rec-title">
                                    <h2>We are Growing!</h2>
                                    <Row>
                                    <Col xs={12} md={7}>
                                        <div className="common-text">
                                            Established in 2002 and with presence across <strong> thirteen cities</strong> in
                                            India, XL Dynamics India Pvt. Ltd. is one of leading service
                                            providers in the Mortgage Process Outsourcing industry. Over
                                            the last few months, the <strong>XLD family has grown by over 1000
                                            members</strong> and we seek thousands more.
                                            <br></br><br></br>
                                            <strong>What you can expect from us:</strong>
                                            <ul>
                                                <li>Leadership that takes pride in having you on their team.</li>
                                                <li>Ethical business practices.</li>
                                                <li>Immediate recognition for the work that you do.</li>
                                                <li>Up to 4 appraisals in 1 year.</li>
                                            </ul>
                                        </div>
                                    
                                    </Col>
                                    <Col xs={12} md={5}>
                                       <a href="https://www.youtube.com/watch?v=WtwSl9GS_s4&t" target="_blank"> <img className="img-fluid" src={insideLook}></img></a>
                                    </Col>
                                    </Row>
                                </div>

                                <div className="rec-title">
                                    <p className="title">We have multiple openings for you with best-in-industry remuneration</p>
                                    <p className="click-title">Click on any position to view the details.</p>
                                    <Row>
                                    <Col xs={12} md={4}>
                                        <div className="small-panel">
                                           <h3>Operations</h3>
                                            <ul>
                                                <li><a onClick={this.selectChange.bind(this, 4)}>Financial Analysts</a></li>
                                                <li><a onClick={this.selectChange.bind(this, 22)}>Team Leaders</a></li>
                                                <li><a onClick={this.selectChange.bind(this, 3)}>Calling Specialists (Voice)</a></li>
                                            </ul>
                                        </div>
                                    
                                    </Col>
                                    <Col xs={12} md={4}>
                                    <div className="small-panel">
                                           <h3>IT</h3>
                                            <ul>
                                                <li><a onClick={this.selectChange.bind(this, 2)}>Application Support Engineers</a></li>
                                                <li><a onClick={this.selectChange.bind(this, 21)}>Front-End Developers</a></li>
                                                <li><a onClick={this.selectChange.bind(this, 18)}>Information Security Officers</a></li>
                                                <li><a onClick={this.selectChange.bind(this, 6)}>IT Help Desk</a></li>
                                                <li><a onClick={this.selectChange.bind(this, 12)}>Software Testers</a></li>
                                                <li><a onClick={this.selectChange.bind(this, 25)}>System Engineers</a></li>
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={4}>
                                    <div className="small-panel">
                                           <h3>Marketing, HR, Admin & Accounts</h3>
                                            <ul>
                                                <li><a onClick={this.selectChange.bind(this, 26)}>Content Specialists</a></li>
                                                <li><a onClick={this.selectChange.bind(this, 10)}>Senior Graphic Designers</a></li>
                                                <li><a onClick={this.selectChange.bind(this, 16)}>HR Executives</a></li>
                                                <li><a onClick={this.selectChange.bind(this, 5)}>HR Offshore</a></li>
                                                <li><a onClick={this.selectChange.bind(this, 24)}>Admin cum Executive Assistants</a></li>
                                                <li><a onClick={this.selectChange.bind(this, 9)}>Senior Accounts Executives</a></li>
                                            </ul>
                                        </div>
                                    </Col>
                                    </Row>                                    
                                </div>
                            </div>
                            <div className="refer-container">                               
                                <p className="rec-refer-title">IF YOU THINK IT’S TIME TO SWITCH AND ARE READY TO MAKE A DIFFERENCE, <br></br> XL DYNAMICS IS THE PLACE TO BE.</p>
                                
                                    <Card.Body fluid="true" className="customBorder referCustomBorder"> 
                                    <strong className="refer-title" style={{color:"#C01722", fontSize: "22pt"}}> Personal Information</strong>
                                        <ApplyNow match={this.props.match} location={this.props.location} employeeID={empID} />
                                    </Card.Body>
                               
                            </div> 
                    </Col>
                    <Col xs={1}></Col>                    
                    <Modal show={show} onHide={this.handleClose.bind(this)}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {filterData.openingTitle} 
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{jobDetails}</Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose.bind(this)}>
                            Close
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </Row>
            </React.Fragment>
        );
    }
}

export default Recruitment;