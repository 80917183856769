import React, { Component} from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Jumbotron, Container, Form, Button, Modal, Image, Toast, Spinner, Nav, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CsvDownload from 'react-json-to-csv';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { MDBDataTable, MDBDataTableV5  } from 'mdbreact';
import axios from 'axios';
import { CSVLink } from "react-csv";
// import SpecialBanner from '../../images/banners/openings/usMortSpl-banner.jpg';
import CareerBanner from '../../images/banners/CurrentOpenings1-banner.jpg';
import CareerBanner2 from '../../images/banners/CurrentOpenings2-banner.jpg';

import JobBlock from '../../components/CreateOpeningBlock';

const TITLE = "CurrentOpenings - XL Dynamics Pvt. Ltd";

const loginEmail = 'admin'
const loginPassword = "xld!@#$"
class ReferEmployee extends Component {
    constructor(props){
        super(props);
        this.state = {
            error : false,
            filterData: [],
            referEmpData : [],
            openingData: [],
            openingCode : this.props.match.params.OpeningName,
            openingName: "",
            startDate: null,
            email:"",
            password:"",
            visiblePanel: false,
            selectedFile: null,
            showTY: false,
            active: false
        };
        this.selectChange = this.selectChange.bind(this)
        this.setStartDate = this.setStartDate.bind(this)
        this.getReferEmployeeData = this.getReferEmployeeData.bind(this)
        this.handleEmailChange = this.handleEmailChange.bind(this)
        this.handlePasswordChange = this.handlePasswordChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.signOut = this.signOut.bind(this)
        this.refresh = this.refresh.bind(this)
        this.toggleMenu = this.toggleMenu.bind(this)
    }
    
    componentDidMount() {
        // fetch("https://xld-development.s3.amazonaws.com/referEmployee.json")
        // .then( response => response.json())
        // .then(
        //     // handle the result
        //     (result) => {
        //         this.setState({
        //             referEmpData : result
        //         });
        //     }
        // )
        this.getReferEmployeeData()
        this.getProfileInfo()
        if(localStorage.getItem('email') == loginEmail && localStorage.getItem('password') == loginPassword){
            this.setState({
                visiblePanel: true
            })
        }
        this.setState({
            error: false
        })
    }  
    // refreshPage() {
    //     window.location.reload(false);
    //     setTYModal(false)        
    //     setFailModal(false)
    // }

    getReferEmployeeData(){
        // fetch("https://xld-development.s3.amazonaws.com/referEmployee.json")
        // fetch("https://xld-development.s3.amazonaws.com/sample-emp-data.json")
        // .then( response => response.json())
        // .then(
        //     // handle the result
        //     (result) => {
        //         this.setState({
        //             referEmpData : result,
        //             filterData:result
        //         });
        //     }
        // )

        var myHeaders = new Headers();
        myHeaders.append('pragma', 'no-cache');
        myHeaders.append('cache-control', 'no-cache');
        const referEmp = {
            "method": "get",
            headers: myHeaders,
        }
        axios.post('https://12cjv4aldg.execute-api.us-west-1.amazonaws.com/prod/referral-Data', referEmp)
            .then((response) => {
                console.log(response.data);
                fetch(response.data.url, referEmp)
                .then( response => response.json())
                .then(
                    // handle the result
                    (result) => {
                        this.setState({
                            referEmpData : result,
                            filterData:result
                        });
                    }
                )
            }, (error) => {
                console.log(error);
            });
            
    }

    getProfileInfo(){
        fetch(process.env.REACT_APP_CURRENT_OPENINGS_CDN_URL)
        .then( response => response.json())
        .then(
            // handle the result
            (result) => {
                this.setState({
                    openingData : result
                });
            }
        )
    }
    
    selectChange(event){
        // this.getReferEmployeeData()
        // alert(event.target.value)
        let filterList = []
        if(event.target.value != "Select role") {
            this.state.filterData.filter( function(data) {
                if(data.position == event.target.value){
                    filterList.push(data)
                }
            });
            this.setState({
                referEmpData : filterList
            });
        } else {
            this.getReferEmployeeData()
        }
    }

    setStartDate(date) {
        this.setState({
            startDate: date
        })
        let filterDate = date ? parseInt(date.getMonth()+1) + "/"+ date.getDate() +"/"+date.getFullYear() : null

        let filterList = []
        this.state.filterData.filter( function(data) {
            var a = new Date(data.date)
            var b = new Date(filterDate)
            if(a >= b){
                filterList.push(data)
            }
        });
        this.setState({
            referEmpData : filterList
        });
    }

    handleEmailChange(event) {
        this.setState({email: event.target.value});
    }

    handlePasswordChange(event) {
        this.setState({password: event.target.value});
    }
   
    handleSubmit(e) {
        e.preventDefault();
        localStorage.setItem('email', loginEmail)
        localStorage.setItem('password', loginPassword)
        if(loginEmail == this.state.email && loginPassword == this.state.password){
            this.setState({
                visiblePanel: true
            })
        } else {
            this.setState({
                error: true
            })
        }        
    }

    signOut() {
        localStorage.removeItem('email');
        localStorage.removeItem('password');
        this.setState({
            visiblePanel: false,
            error: false
        })
    }

    refresh() {
        window.location.reload(false);
    }

    uploadFile = event => {
        let file = event.target.files;
        console.log(file);
        this.setState({
            selectedFile: file
        })
        // setFileSelection(file); 
    }

    handleSubmitEMP = event => { 
        event.preventDefault();
        let file = this.state.selectedFile[0];
        axios("https://02tsbcu838.execute-api.us-east-1.amazonaws.com/default/getpresignedURL?file="+this.state.selectedFile[0].name)
        .then(response => {
            const urlNew = response.data.uploadURL;
            // setUrl(url)
            console.log("Recieved a signed request " + urlNew);

            var options = {
                headers: {
                'Content-Type': file.type
                }
            };
            axios({
                method: "PUT",
                url: urlNew,
                data: file,
                headers: { "Content-Type": "multipart/form-data" }
            })
            .then(result => {
                console.log("Response from s3", result)
                this.setState({
                    showTY: true
                })
            })
            .catch(error => {
                // alert("ERROR " + JSON.stringify(error));
                console.log("Put error status " +  JSON.stringify(error));
            })
        })
        .catch(error => {
            console.log(JSON.stringify(error));
        })
    }

    toggleMenu() {
        var toggle = this.state.active == false ? true : false;
          this.setState( {
            active: toggle
          });
    }

    render() {
        const columns = [
            {
                label: 'Date',
                field: 'date',
                sort: 'desc',
                width: 150
              },
              {
                label: 'Referred by Emp ID',
                field: 'emp',
                // sort: 'desc',
                width: 150
              },
              {
                label: 'Candidate Name',
                field: 'firstName',
                // sort: 'desc',
                width: 150
              },
              {
                label: 'Last Name',
                field: 'lastName',
                // sort: 'desc',
                width: 150
              },
              {
                label: 'Candidate Email',
                field: 'email',
                // sort: 'desc',
                width: 150
              },
              {
                label: 'contact Number',
                field: 'contactNumber',
                // sort: 'desc',
                width: 150
              },
              {
                label: 'city',
                field: 'city',
                // sort: 'desc',
                width: 150
              },
              {
                label: 'Applied Position',
                field: 'jobSelect',
                // sort: 'desc',
                width: 150
              },
              {
                label: 'Resume link',
                field: 'resume',
                // sort: 'desc',
                width: 150
              }             
        ]
        const {referEmpData, openingData, startDate, visiblePanel, error, showTY, active} = this.state;
        const data = {columns: columns ,
            rows: referEmpData}

        const openingsList = openingData.map(oData => {
            let title = oData.openingTitle;
            if(oData.visible == 1){
                return <option key={oData.openingId} value={oData.openingTitle}>{title}</option>;
            }
        });
        return(
            <div className="table-responsive text-nowrap p-5" >
                <div className={"row col-md-12 " + (visiblePanel ? ' d-block' : ' d-none')}>   
                    <p className="text-right">
                        <button className="btn btn-primary" type="button" onClick={this.toggleMenu}>
                        Upload Employee info & College Data
                        </button>
                    </p>
                    <div className={active? 'd-block' : 'd-none'}>
                    <div className="jumbotron card card-image container-fluid p-3">
                        <h2 className="text-center pb-3">Welcome message for students & information for job seeker</h2>
                        <div className="row make-center border p-3">
                            <Form id="applyNow" className="form-inline" encType="multipart/form-data" onSubmit={this.handleSubmitEMP} method="POST" autoComplete="off">
                                <div className="col-xs-12 form-group mr-2"><h4>Upload Employee info & College Data: </h4> </div>
                                <div className="col-xs-12 form-group mr-2">
                                    <span>
                                    <input type="file"
                                    name="myFile"
                                    onChange={this.uploadFile} required/>
                                    </span>
                                </div>
                                <div className="col-xs-12 form-group mr-2">                        
                                    <Button variant="danger" type="btn btn-lg submit" >Upload</Button>
                                </div>
                            </Form>
                        </div>
                        <Modal size='md' centered show={showTY} backdrop="static">
                            <Modal.Body>Thanks, File uploded Successfully. </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={this.refresh}>Close</Button>
                                {/* <Nav.Link as={Link} to={`/ApplyNow/${selectOptionNew}`} className="btn btn-danger btn-sm">Close</Nav.Link> */}
                                {/* <Nav.Link as={Link} to="/employee-list" className="btn btn-danger btn-sm">Close</Nav.Link> */}
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
                </div>    
                    <h2 className="text-center pb-2">List of Employees Reference</h2>
                    <div className={"row" + (visiblePanel ? ' d-none ' : ' d-block')}>                        
                        <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                        {
                            error ? 
                            <div className="alert alert-danger" role="alert">
                                Please login with valid username and password 
                            </div> : ''
                        }
                        
                            <div className="card card-signin my-5">
                                <div className="card-body">
                                    <h5 className="card-title text-center">Sign In</h5>
                                    <form className="form-signin" onSubmit={this.handleSubmit}>
                                    <div className="form-label-group">
                                        <input type="text" id="email" className="form-control" value={this.state.email} onChange={this.handleEmailChange} placeholder="Email address" required autofocus />
                                        <label for="email">User Name</label>
                                    </div>

                                    <div className="form-label-group">
                                        <input type="password" id="password" className="form-control" value={this.state.password} onChange={this.handlePasswordChange} placeholder="Password" required />
                                        <label for="password">Password</label>
                                    </div>                                
                                    <button className="btn btn-lg btn-danger btn-block text-uppercase" type="submit">Sign in</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        </div>

                    <div className={"row col-md-12 " + (visiblePanel ? ' d-block' : ' d-none')}>                        
                        <div className=" row make-center border pt-3 pr-3 mb-5 mt-3">
                            <div className="col-md-2 col-sm-12 text-right"><strong>Filter  by Role </strong> </div>
                            <div className="col-md-2 col-sm-12 pb-2">
                                <select name="select" class="form-control" onChange={this.selectChange} required>
                                <option key="Select role" value="Select role" selected={true}>Show All</option> 
                                {openingsList}
                            </select>
                            </div>
                            <div className="col-md-2 col-sm-12 text-right"><strong>Filter  by Date </strong></div>
                            <div className="col-md-2 col-sm-12">
                                <DatePicker className="form-control" isClearable selected={this.state.startDate} onChange={this.setStartDate} />
                            </div>
                            <div className="button-panel col-md-4 col-sm-12">
                                <button className="btn btn-md btn-warning btn-block text-uppercase" type="button" style={{left:"0"}} onClick={this.refresh}>Refresh</button>
                                {/* <CsvDownload 
                                data={referEmpData}
                                style={{ //pass other props, like styles
                                    backgroundColor: "#28a745",
                                    cursor: "pointer",
                                    fontSize:"15px",
                                    fontWeight: "bold",
                                    margin: "0 10px",
                                    display: "block",
                                    border: "1px solid #1c7430",
                                    color: "#ffffff",
                                    padding: "5px",
                                    borderRadius: "5px",
                                    position: "absolute",
                                    right:"120px"
                                }}
                                className="text-center"
                                >Export to CSV</CsvDownload> */}
                                <CSVLink 
                                    data={referEmpData} 
                                    filename={"candidateData.csv"}
                                    style={{ //pass other props, like styles
                                        backgroundColor: "#28a745",
                                        cursor: "pointer",
                                        fontSize:"15px",
                                        fontWeight: "bold",
                                        margin: "0 10px",
                                        display: "block",
                                        border: "1px solid #1c7430",
                                        color: "#ffffff",
                                        padding: "5px",
                                        borderRadius: "5px",
                                        position: "absolute",
                                        right:"120px"
                                    }}
                                    className={referEmpData.length > 0 ? "text-center" : "disabled"}>
                                    {referEmpData.length > 0 ? 'Export to CSV' : 'Please wait....'}
                                </CSVLink>

                                <button className="btn btn-md btn-danger btn-block text-uppercase" type="button" onClick={this.signOut}>Sign out</button>
                                
                        </div>
                        </div>
                        
                   
                    {/* <table className="table table-striped w-auto" style={{margin:"0 auto", minWidth:"100%"}}>

                        <thead>
                        <tr>
                            <th>#</th>
                            <th className="th-lg">Date</th>
                            <th className="th-lg">Referred by Employee ID</th>
                            <th className="th-lg">Candidate Name</th>
                            <th className="th-lg">Candidate Email</th>
                            <th className="th-lg">contact Number</th>
                            <th className="th-lg">city</th>
                            <th className="th-lg">Applied Position</th>
                            <th className="th-lg">Resume</th>
                        </tr>
                        </thead>
                        <tbody>
                            {
                            referEmpData.map((employee, index) => 
                                <tr>
                                    <th scope="row">{index + 1}</th>
                                    <td>{employee.date}</td>
                                    <td>{employee.emp}</td>
                                    <td>{employee.candidateFName} {employee.candidateLName}</td>
                                    <td>{employee.candidateEmail}</td>
                                    <td>{employee.contactNumber}</td>
                                    <td>{employee.city}</td>
                                    <td>{employee.position}</td>
                                    <td><a href={employee.resume} target="_blank">Download</a> </td>
                                </tr>
                            )

                            }
                    
                        
                        </tbody>

                    </table>*/}
                   
                    {data.rows.length == 0 ? 
                        // <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                        <div style={{textAlign:"center"}}>                        
                        <Spinner animation="grow" variant="primary" />
                        <Spinner animation="grow" variant="secondary" />
                        <Spinner animation="grow" variant="success" />
                        <Spinner animation="grow" variant="danger" />
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="info" />
                        <Spinner animation="grow" variant="light" />
                        {/* <Spinner animation="grow" variant="dark" /> */}
                        </div>
                        :
                        <MDBDataTable
                        striped
                        bordered
                        small
                        entries={20}
                        entriesOptions={[10, 20, 40, 80, 100, 200, 300]}
                        data={data}
                        hover
                        searchLabel="Search with anything like Emp ID, Candidate name, email, positions, city etc"
                        />
                    }
                     </div>
                </div>
        );
    }
}

export default ReferEmployee;