import React from "react";
import { Link } from "react-router-dom";
import "../css/jobOepning.css";
import { IoMdArrowRoundForward } from 'react-icons/io';

function CreateOpeningBlock (props) {
    {/* <Link to={`/Opening/${props.title}`}>{props.title}</Link> */}
    return(
        <Link to={`/Opening/${props.openingCode}`}>
            <div className="react-job-li">
                <li>
                    <div className="jbDet">
                        <div className="title">{props.title}</div>
                    </div>
                    <div className="jbArrow"><IoMdArrowRoundForward /></div>
                </li>
            </div>
        </Link>
    );
}

export default CreateOpeningBlock;